import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import {useSendFeedbackMutation} from "../services/trades";
import Layout from "../components/layout";
import {addToast} from "../store/slices/toast";
import {useDispatch} from "react-redux";

const Feedback = () => {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const dispatch = useDispatch();

    const [sendFeedback] = useSendFeedbackMutation();

    const showToast = (title: string, message: string) => {
        const toast = { id: Date.now(), title, message };
        dispatch(addToast(toast));
    };


    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const body = {
            title: title,
            content: content
        }
        sendFeedback(body);
        showToast('Thank you for your feedback', 'Message sent successfully!')
        setTitle("");
        setContent("");
    };

    return (
        <Layout>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={6}>
                        <h2 className="text-center mb-4">Send Feedback</h2>
                        <p className="text-center text-white">
                            Write to us if you've found any bugs, experience issues with the application, or have suggestions for improvements.
                            Your feedback is invaluable in helping us enhance the platform. Whether it's a feature request or a new idea, we'd love to hear from you!
                        </p>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formTitle">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="formContent" className="mt-3">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    required
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter message"
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit" className="mt-4">
                                Send
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default Feedback;
