import React, {useEffect, useState} from 'react';
import Layout from "../components/layout";
import {Row, Table} from "react-bootstrap";
import {Rule, Tip} from "../models/trade";
import CustomModal from "../components/custom-modal";
import TipTable from "./tip";
import RuleTable from "./rule";
import {useTradingPlan} from "../hooks/trading-plan.hook";
import TextInput from "../components/inputs/input";
import Button from "react-bootstrap/Button";
import {addToast} from "../store/slices/toast";
import {useDispatch} from "react-redux";
import '../styles/components/table.scss';
import {
    useGetBalanceHistoryQuery,
    useGetBalanceQuery,
    useUpdateBalanceMutation
} from "../services/balance-history";
import TimeSeriesChart from "../components/charts/timeserieschart";
import {BsCaretLeftFill, BsCurrencyDollar, BsPercent} from "react-icons/bs";
import StatCard from "../components/statcard";

const TradingPlan = () => {
    const {
        rules,
        tips,
        createNewRule,
        editRule,
        deleteRule,
        createNewTip,
        editTip,
        deleteTip,
        getTradingPlan,
        getTradingPlanSummary,
        updateTradingPlan
    } = useTradingPlan();
    const { data: tradingPlan } = getTradingPlan;
    const { data: tradingPlanSummary } = getTradingPlanSummary;
    const { data: accountBalance } = useGetBalanceQuery('');
    const [ updateBalance ] = useUpdateBalanceMutation();
    const { data: historyData } = useGetBalanceHistoryQuery('');

    const dispatch = useDispatch();
    const [balance, setBalance] = useState(10000);
    const [commission, setCommission] = useState(7);
    const [dailyTrades, setDailyTrades] = useState(4);
    const [riskPerDay, setRiskPerDay] = useState(2);
    const [riskPerTrade, setRiskPerTrade] = useState(0.5);

    const [showTip, setShowTip] = useState(false);
    const [showBalance, setShowBalance] = useState(false);
    const [action, setAction] = useState('setup');
    const [showRule, setShowRule] = useState(false);
    const [showTradingPlan, setShowTradingPlan] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState('');
    const [deleteMessage, setDeleteMessage] = useState('');
    const [ruleName, setRuleName] = useState('');
    const [tipName, setTipName] = useState('');
    const [ruleId, setRuleId] = useState(0);
    const [tipId, setTipId] = useState(0);
    const [isTipDelete, setIsTipDelete] = useState(false);
    const [createTip, setCreateTip] = useState(true);
    const [createRule, setCreateRule] = useState(true);

    const deleteTips = async () => {
        if (isTipDelete) {
            await deleteTip(tipId);
            showToast('Delete Tip', 'Tip deleted successfully!')
        } else {
            await deleteRule(ruleId);
            showToast('Delete Rule', 'Rule deleted successfully!')
        }
        setIsTipDelete(false);
        setShowDeleteModal(false);

    }

    const openDeleteModal = async (data: any, title: string) => {
        setDeleteTitle(title);
        if (data.tip) {
            setIsTipDelete(true);
            setDeleteMessage('Are you sure you want to delete this tip' + data.tip);
            setTipName(data.tip);
            setTipId(data.id);
        } else {
            setIsTipDelete(false);
            setDeleteMessage('Are you sure you want to delete this rule' + data.rule);
            setRuleName(data.tip);
            setRuleId(data.id);
        }
        setShowDeleteModal(true);
    };

    const handleClose = () => {
        setShowRule(false);
        setShowTip(false);
        setShowTradingPlan(false);
        setShowBalance(false);
        setShowDeleteModal(false);
    };

    const openEditRuleModal = (rule: Rule) => {
        setRuleName(rule.rule);
        setRuleId(rule.id);
        setShowRule(true);
        setCreateRule(false);
    };

    const openEditTipModal = (tip: Tip) => {
        setTipName(tip.tip);
        setTipId(tip.id);
        setShowTip(true);
        setCreateTip(false);
    };

    const openCreateTipModal = () => {
        setTipName('');
        setCreateTip(true);
        setShowTip(true);
    };

    const openCreateRuleModal = () => {
        setRuleName('');
        setCreateRule(true);
        setShowRule(true);
    }

    const openEditTradingPlanModal = () => {
        setShowTradingPlan(true);
    }

    const openBalanceModal = (type: string) => {
        if (type === 'Deposit') {
            setAction('Deposit');
        } else if( type === 'Withdraw') {
            setAction('Withdraw');
        } else {
            setAction('setup');
        }
        setShowBalance(true);
    }


    const handleRuleSubmit = async (e: any) => {
        e.preventDefault();
        if (createRule) {
            await createNewRule({
                rule: ruleName,
                trading_plan: false
            });
            handleClose();
            showToast('Create Rule', 'Rule created successfully!')
        } else {
            const ruleBody = {
                rule: ruleName,
                trading_plan: false
            };
            await editRule({
                rule: ruleBody,
                ruleId
            });
            handleClose();
            showToast('Edit Rule', 'Rule edited successfully!')
        }
    };

    const handleTipSubmit = async (e: any) => {
        e.preventDefault();
        if (createTip) {
            await createNewTip({
                tip: tipName
            });
            showToast('Create Tip', 'Tip created successfully!')
        } else {
            await editTip({
                tip: {tip: tipName},
                tipId
            });
            showToast('Edit Tip', 'Tip edited successfully!')
        }
        handleClose();
    };

    const handleBalanceSubmit = async (e: any) => {
        e.preventDefault();
        let sendBalance = balance;
        if (action === 'Withdraw') {
            sendBalance = -balance;
        }
        await updateBalance({
            tradeProfit: sendBalance,
            userId: 0
        });
        showToast('Balance Setup', 'Balance setuped successfully!')
        handleClose();
    };

    const showToast = (title: string, message: string) => {
        const toast = { id: Date.now(), title, message };
        dispatch(addToast(toast));
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const tradingPlanData: { [key: string]: any } = {
            risk_per_trade: riskPerTrade,
            commission: commission,
            daily_trades: dailyTrades,
            risk_per_day: riskPerDay,
        };

        await updateTradingPlan(tradingPlanData);
        setShowTradingPlan(false)
        showToast('Edit Trading Plan', 'Trading Plan edited successfully!')
    };

    const tradingPlanConfig = [
        { title: "Risk per trade", data: `${tradingPlan?.risk_per_trade}%`, IconComponent: BsPercent, bg: "light", textColor: "black"},
        { title: "Risk per day", data: `${tradingPlan?.risk_per_day}%`, IconComponent: BsPercent, bg: "light", textColor: "black"},
        { title: "Commision per lot", data: `${tradingPlan?.commission} $`, IconComponent: BsCurrencyDollar, bg: "light", textColor: "black" },
        { title: "Trades per day", data: tradingPlan?.daily_trades, IconComponent: BsCaretLeftFill, bg: "light", textColor: "black" }
    ];

    useEffect(() => {
        if (accountBalance) {
            setBalance(accountBalance);
        }
    }, [accountBalance]);

    useEffect(() => {
        if (tradingPlan) {
            setCommission(tradingPlan.commission);
            setDailyTrades(tradingPlan.daily_trades);
            setRiskPerDay(tradingPlan.risk_per_day);
            setRiskPerTrade(tradingPlan.risk_per_trade);
        }
    }, [tradingPlan]);

    return (
        <Layout>
            <div>
                <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
                    <h2 className='mt-3'>Trading Plan</h2>
                    <div className="flex-shrink-0">
                        {accountBalance === 0 &&
                            <Button variant='primary' className='mt-3' onClick={() => openBalanceModal('setup')}>
                                Setup Start Balance
                            </Button>
                        }
                        {accountBalance > 0 &&
                            <>
                                <Button variant='primary' className='mt-3 mx-2' onClick={() => openBalanceModal('Deposit')}>
                                    Deposit
                                </Button>
                                <Button variant='primary' className='mt-3' onClick={() => openBalanceModal('Withdraw')}>
                                    Withdraw
                                </Button>
                            </>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 mt-3">
                        {historyData &&
                            <TimeSeriesChart series={[{ data: historyData }]} chartTitle='Balance per each update'/>
                        }
                    </div>
                    {tradingPlanConfig.map((stat: any, index: number) => (
                    <StatCard key={index} {...stat} lgValue={3} />
                    ))}
                </div>

                <Button variant='primary' className='mt-3' onClick={openEditTradingPlanModal}>
                    Edit trading plan
                </Button>
                <h2 className='mt-3'>Trading Plan Analysis</h2>
                <div className="table-container">
                    <Table className="table mt-3" striped bordered hover variant='dark'>
                        <thead>
                        <tr>
                            <th>Number of trades</th>
                            <th>Exceeded number of trades</th>
                            <th>Exceeded daily risk</th>
                            <th>Balance change %</th>
                            <th>Balance change $</th>
                            <th>Commission</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tradingPlanSummary?.map((stats: any) => (
                            <tr key={stats.id}>
                                <td>{stats.tradesMade}</td>
                                <td>{stats.exceededTradeLimit ? 'Yes' : 'No'}</td>
                                <td>{stats.exceededDailyRisk ? 'Yes' : 'No'}</td>
                                <td>{stats.balanceChangePercentage} %</td>
                                <td>$ {stats.balanceChange}</td>
                                <td>$ {stats.totalCommissionToday}</td>
                                <td>{stats.date}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
                <Row className='mt-4'>
                   <div className="col-lg-6">
                       <RuleTable
                           rules={rules}
                           openCreateRuleModal={openCreateRuleModal}
                           openEditRuleModal={openEditRuleModal}
                           openDeleteModal={openDeleteModal}
                       />
                   </div>
                   <div className="col-lg-6">
                       <TipTable
                           tips={tips}
                           openCreateTipModal={openCreateTipModal}
                           openEditTipModal={openEditTipModal}
                           openDeleteModal={openDeleteModal}
                       />
                   </div>
                </Row>
            </div>

            <CustomModal
                show={showRule}
                handleClose={handleClose}
                title={createRule ? 'Create new rule' : 'Edit Rule'}
                actionTitle={createRule ? 'Create' : 'Edit'}
                content={
                    <form className="container">
                        <div className="row mb-12">
                            <label className="form-label">Rule name</label>
                            <input
                                type="text"
                                value={ruleName}
                                onChange={(e) => setRuleName(e.target.value)}
                                placeholder="Rule name"
                                className="form-control"
                            />
                        </div>
                    </form>
                }
                onSubmit={handleRuleSubmit}
            />

            <CustomModal
                show={showTradingPlan}
                handleClose={handleClose}
                title='Edit Trading Plan'
                actionTitle='Edit'
                size='lg'
                content={
                    <form className="container mt-3">
                        <div className="row mb-3">
                            <div className="col-md-3">
                                <TextInput type="number" value={riskPerTrade} onChange={(val: any) => setRiskPerTrade(val)} placeholder="Risk per trad %e" label="Risk per trade %" />
                            </div>
                            <div className="col-md-3">
                                <TextInput type="number" value={riskPerDay} onChange={(val: any) => setRiskPerDay(val)} placeholder="Risk per day %" label="Risk per day %" />
                            </div>
                            <div className="col-md-3">
                                <TextInput type="number" value={commission} onChange={(val: any) => setCommission(val)} placeholder="Commision per 1 lot $" label="Commision per 1 lot $" />
                            </div>
                            <div className="col-md-4 mt-3">
                                <TextInput type="number" value={dailyTrades} onChange={(val: any) => setDailyTrades(val)} placeholder="Number of trade per day" label="Number of trade per day" />
                            </div>
                        </div>
                    </form>
                }
                onSubmit={handleSubmit}
            />

            <CustomModal
                show={showTip}
                handleClose={handleClose}
                title={createTip ? 'Create new tip' : 'Edit tip'}
                actionTitle={createTip ? 'Create' : 'Edit'}
                content={
                    <form className="container">
                        <div className="row mb-12">
                            <label className="form-label">Tip name</label>
                            <input
                                type="text"
                                value={tipName}
                                onChange={(e) => setTipName(e.target.value)}
                                placeholder="Tip name"
                                className="form-control"
                            />
                        </div>
                    </form>
                }
                onSubmit={handleTipSubmit}
            />

            <CustomModal
                show={showBalance}
                handleClose={handleClose}
                title={action === 'setup' ? 'Setup start balance' : `${action}`}
                actionTitle={action}
                content={
                    <form className="container">
                        <div className="row mb-12">
                            {action === 'setup' &&
                                <label className="form-label">Start balance</label>
                            }
                            <input
                                type="text"
                                value={balance}
                                onChange={(e) => setBalance(Number(e.target.value))}
                                placeholder="Value"
                                className="form-control"
                            />
                        </div>
                    </form>
                }
                onSubmit={handleBalanceSubmit}
            />

            <CustomModal
                show={showDeleteModal}
                handleClose={handleClose}
                title={deleteTitle}
                confirmButtonVariant='danger'
                actionTitle='Delete'
                content={
                    <p>{deleteMessage}</p>
                }
                onSubmit={() => deleteTips()}
            />
        </Layout>
    );
}

export default TradingPlan;
