import React from 'react';
import Chart from 'react-apexcharts';

const PieChart = ({ chartPairData, height = 500, legendPosition = 'bottom', type = 'pie', showLegend = true }: any) => {
    if (!chartPairData || chartPairData.length === 0) {
        return null;
    }

    const chartData = chartPairData.map((entry: any) => ({
        x: entry.name,
        y: parseInt(entry.value),
    }));

    const chartOptions: any = {
        chart: {
            type: 'pie',
            foreColor: 'white'
        },
        colors: ['#bf0f1b', '#0090fb', '#5d1766', '#f07400', '#1800f0'],
        labels: chartData.map((entry: any) => entry.x),
        legend: {
            position: legendPosition,
            show: showLegend
        },
    };

    const series = chartData.map((entry: any) => entry.y);

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    };

    return (
        <div style={containerStyle}>
            <Chart options={chartOptions} series={series} type={type} height={height} />
        </div>
    );
};

export default PieChart;
