import React, { useEffect, useState } from 'react';
import {useGetCalendarTradesQuery, useGetFiltersQuery,} from "../services/trades";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from "moment";
import Layout from "../components/layout";
import TradeModal from "../components/trade-modal";
import Select from "react-select";
import {
    BsBarChart,
    BsCaretLeftFill,
    BsExclamationTriangle,
    BsGraphUp,
    BsPercent,
    BsQuestionCircle
} from "react-icons/bs";
import StatCard from "../components/statcard";

const TradeCalendar: React.FC = () => {
    const [visibleStart, setVisibleStart] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [visibleEnd, setVisibleEnd] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    const [events, setEvents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedTrades, setSelectedTrades] = useState([]);
    const [selectedType, setSelectedType] = useState<any>(null);
    const [selectedPairs, setSelectedPairs] = useState<any>([]);
    const { data } = useGetCalendarTradesQuery({ startDate: moment(visibleStart).format('YYYY-MM-DD'), endDate: moment(visibleEnd).format('YYYY-MM-DD'), pairs: selectedPairs, type: selectedType });
    const { data: filters } = useGetFiltersQuery({startDate: moment(visibleStart).format('YYYY-MM-DD'), endDate: moment(visibleEnd).format('YYYY-MM-DD'), pairs: selectedPairs, type: selectedType});

    const typeOptions = [
        { value: 'Buy', label: 'Buy' },
        { value: 'Sell', label: 'Sell' },
        { value: null, label: 'All' },
    ];

    const pairOptions = filters?.pairs?.map((pair: string) => ({ value: pair, label: pair })) || [];
    const handleDatesSet = ({ startStr, endStr }: any) => {
        setVisibleStart(startStr);
        setVisibleEnd(endStr);
    };

    const handleEventClick = (info: any) => {
        const clickedDate = info.event.startStr;
        const tradesForDate = data?.dailySummaries.filter((trade: any) => trade.date === clickedDate)[0].trades;
        setSelectedTrades(tradesForDate);
        setShowModal(true);
    };


    const renderEventContent = (eventInfo: any) => {
        const trade = eventInfo.event.extendedProps.trade;
        return (
            <div>
                <div className="event-title">Profit: { trade?.totalProfit ? trade?.totalProfit.toFixed(2): 0} $</div>
                <div className="event-details">
                    <div>Trades: {trade?.numberOfTrades}</div>
                    <div>Commission: {trade?.totalCommission ? trade?.totalCommission.toFixed(2): 0}</div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (data?.dailySummaries) {
            const formattedEvents = data.dailySummaries.map((trade: any) => ({
                start: trade.date,
                extendedProps: { trade },
                color: trade.totalProfit > 0 ? '#0ECB81' : (trade.totalProfit < 0 ? '#dc3545' : 'gray'),
            }));
            setEvents(formattedEvents);
        }
    }, [data]);

    const statsConfig = [
        { title: "Total Trades", data: data?.overallStats.totalTrades, IconComponent: BsCaretLeftFill, bg: "light", textColor: "black" },
        { title: "Pips", data: data?.overallStats.totalPips, IconComponent: BsGraphUp, bg: "light", textColor: "black" },
        { title: "Outcome", data: `$ ${data?.overallStats.totalProfit}`, IconComponent: BsBarChart, bg: "light", textColor: "black" },
        { title: "Mistakes", data: data?.overallStats.totalMistakes, IconComponent: BsExclamationTriangle, bg: "light", textColor: "black" },
        { title: "Biggest Win", data: `$ ${data?.overallStats.maxProfitTrade}`, IconComponent: BsQuestionCircle, bg: "light", textColor: "black" },
        { title: "Biggest Loss", data: `$ ${data?.overallStats.minProfitTrade}`, IconComponent: BsQuestionCircle, bg: "light", textColor: "black" },
        { title: "Most Traded Pair", biggerCard: true, data: data?.overallStats.mostTradedPair, IconComponent: BsQuestionCircle, bg: "light", textColor: "black" },
        { title: "Total Buys", data: data?.overallStats.totalBuys, IconComponent: BsCaretLeftFill, bg: "light", textColor: "black" },
        { title: "Total Sells", data: data?.overallStats.totalSells, IconComponent: BsGraphUp, bg: "light", textColor: "black" },
        { title: "Win Ratio", data: `${data?.overallStats.win_ratio}%`, IconComponent: BsBarChart, bg: "light", textColor: "black" },
        { title: "Biggest % Gain ", data: `${data?.overallStats?.biggestGain.percentage} %`, IconComponent: BsPercent, bg: "light", textColor: "black" },
        { title: "Max DrawDown %", data: `${data?.overallStats?.biggestDrawdown.percentage} %`, IconComponent: BsPercent, bg: "light", textColor: "black" },
    ];


    return (

        <Layout>
            <div className="container mt-3">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md-3">
                        <h2>Trading Calendar</h2>
                    </div>
                    <div className="col-md-9">
                        <div className="d-flex justify-content-end align-items-center">
                            <Select
                                value={selectedType}
                                onChange={(selectedOption) => setSelectedType(selectedOption)}
                                options={typeOptions}
                                placeholder="Type"
                                className="mx-2"
                            />
                            <Select
                                value={selectedPairs}
                                onChange={(selectedOptions) => setSelectedPairs(selectedOptions)}
                                options={pairOptions}
                                placeholder="Pairs"
                                isMulti
                                className="mx-2"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                {statsConfig.map((stat, index) => (
                    <StatCard key={index} lgValue={3} {...stat} />
                ))}
            </div>
            <div className="trade-calendar">
                <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridWeek"
                    events={events}
                    eventContent={renderEventContent}
                    datesSet={handleDatesSet}
                    eventClick={handleEventClick}
                    headerToolbar={{
                        left: 'prev,next',
                        center: 'title',
                        right: 'dayGridMonth,dayGridWeek,dayGridDay'
                    }}
                />
            </div>
            <TradeModal
                show={showModal}
                trades={selectedTrades}
                onHide={() => setShowModal(false)}
            />
        </Layout>
    );
};

export default TradeCalendar;
