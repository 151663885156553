import React from 'react';
import {useGetMonthlyMarketJournalQuery} from "../../services/market-journal";

import MarketJournal from "./MarketJournal";

const MonthlyMarketJournal = () => {
    return (
        <MarketJournal
            queryFunction={useGetMonthlyMarketJournalQuery}
            title="Monthly Market Journal Analysis"
            chartTitle="Monthly Market Journal Analysis Overview"
            type='month'
        />
    );
}

export default MonthlyMarketJournal;
