import { configureStore } from '@reduxjs/toolkit'
import {toastSlice} from "./slices/toast";
import {authApi} from "../services/auth";
import {setupListeners} from "@reduxjs/toolkit/query";
import {tradesApi} from "../services/trades";
import {tradingPlanApi} from "../services/trading_plan";
import {reportsApi} from "../services/reports";
import {logout, userSlice} from "./slices/user";
import {marketJournalApi} from "../services/market-journal";
import {balanceApi} from "../services/balance-history";

const unauthorizedMiddleware = (storeAPI: any) => (next: any) => (action:any) => {
    if (action.payload?.data?.statusCode && action.payload?.data.statusCode === 401) {
        storeAPI.dispatch(logout());
    }
    return next(action);
};


export const store = configureStore({
    reducer: {
        toast: toastSlice.reducer,
        user: userSlice.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [reportsApi.reducerPath]: reportsApi.reducer,
        [tradesApi.reducerPath]: tradesApi.reducer,
        [tradingPlanApi.reducerPath]: tradingPlanApi.reducer,
        [marketJournalApi.reducerPath]: marketJournalApi.reducer,
        [balanceApi.reducerPath]: balanceApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            authApi.middleware,
            reportsApi.middleware,
            tradesApi.middleware,
            tradingPlanApi.middleware,
            marketJournalApi.middleware,
            balanceApi.middleware,
            unauthorizedMiddleware
        ),
})

setupListeners(store.dispatch)

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
