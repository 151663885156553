import { useEffect } from 'react';
import {
    useGetRulesQuery,
    useDeleteRuleMutation,
    useCreateRuleMutation,
    useEditRuleMutation,
    useGetTipsQuery,
    useDeleteTipMutation,
    useCreateTipMutation,
    useEditTipMutation, useEditTradingPlanMutation, useGetTradingPlanQuery, useGetTradingPlanSummaryQuery,
} from '../services/trading_plan';

export function useTradingPlan() {
    const rulesQuery = useGetRulesQuery('');
    const [sendDeleteRule] = useDeleteRuleMutation();
    const [createNewRule] = useCreateRuleMutation();
    const [editRule] = useEditRuleMutation();
    const tipsQuery = useGetTipsQuery('');
    const [sendDeleteTip] = useDeleteTipMutation();
    const [createNewTip] = useCreateTipMutation();
    const [editTip] = useEditTipMutation();
    const getTradingPlan = useGetTradingPlanQuery('');
    const getTradingPlanSummary = useGetTradingPlanSummaryQuery('');
    const [updateTradingPlan] = useEditTradingPlanMutation();


    useEffect(() => {
        rulesQuery.refetch();
        tipsQuery.refetch();
    }, []);

    return {
        rules: rulesQuery.data,
        tips: tipsQuery.data,
        createNewRule: createNewRule,
        editRule,
        getTradingPlan,
        getTradingPlanSummary,
        updateTradingPlan,
        deleteRule: sendDeleteRule,
        createNewTip: createNewTip,
        editTip,
        deleteTip: sendDeleteTip,
        getRules: rulesQuery.refetch,
        getTips: tipsQuery.refetch,

    };
}
