import React from 'react';
import '../styles/components/login.scss';
import bgImage from '../assets/images/features.avif'

const AuthContainer = ({ children }: any) => {
    return (
        <div className="login-container">
            <div className="image-container">
                <img src={bgImage}/>
            </div>
            <div className="form-container">
                <div className="box">
                    <div className="form">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthContainer;
