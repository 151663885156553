import React, {useEffect} from 'react';

type FullScreenImageModalProps = {
    isVisible: boolean;
    imageUrl: string;
    onClose: () => void;
};

const FullScreenImageModal= ({ isVisible, imageUrl, onClose }: FullScreenImageModalProps) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    if (!isVisible) return null;

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000
            }}
            onClick={onClose}
        >
            <img src={imageUrl} alt="Full Screen Image" style={{ maxWidth: '90%', maxHeight: '90%' }} />
        </div>
    );
}

export default FullScreenImageModal;
