import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APP_API_BASE } from './constants';
import { prepareHeaders } from '../utils/apiUtils';

export const reportsApi = createApi({
    reducerPath: 'report',
    baseQuery: fetchBaseQuery({
        baseUrl: APP_API_BASE,
        prepareHeaders: prepareHeaders,
    }),
    endpoints: (builder) => ({
        getReport: builder.query({
            query: ({reportType, startDate, endDate}) => ({
                url: `trades/${reportType}?startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
            }),
        }),
        getReportProfile: builder.query({
            query: ({reportType, value, startDate, endDate}) => ({
                url: `trades/details/${reportType}/${value}?startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetReportQuery,
    useGetReportProfileQuery
} = reportsApi;
