import React from 'react';
import {PaginationModel} from "../models/trade";
import Pagination from 'react-bootstrap/Pagination';

const PaginationComponent = ({ currentPage, totalItems, itemsPerPage, onPageChange }: PaginationModel) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);


    const startPage = Math.max(currentPage - 3, 1);
    const endPage = Math.min(currentPage + 3, totalPages);

    let items = [];

    if (startPage > 1) {
        items.push(
            <Pagination.Item key={1} onClick={() => onPageChange(1)}>
                1
            </Pagination.Item>
        );
        if (startPage > 2) {
            items.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }
    }

    for (let number = startPage; number <= endPage; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => onPageChange(number)}
            >
                {number}
            </Pagination.Item>
        );
    }

    if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
            items.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }
        items.push(
            <Pagination.Item
                key={totalPages}
                onClick={() => onPageChange(totalPages)}
            >
                {totalPages}
            </Pagination.Item>
        );
    }

    return (
        <Pagination>
            <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} />
            {items}
            <Pagination.Next onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        </Pagination>
    );
};

export default PaginationComponent;
