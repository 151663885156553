import React, {useEffect, useState} from 'react';
import Layout from "../components/layout";
import {Button, Table} from "react-bootstrap";
import {useImportTradesMutation, useUploadFileMutation} from "../services/trades";
import DataLoadHandler from "../components/helpers/dataloadhandler";
import {useDispatch} from "react-redux";
import {addToast} from "../store/slices/toast";
import importImage from '../assets/images/import.png';

const ImportTrades = () => {
    const [isLoading, setIsLoading] = useState(false);
    let [createTrade, {data, isError}] = useUploadFileMutation();
    let [importAllTrades] = useImportTradesMutation();
    const [localTrades, setLocalTrades] = useState<any>([]);
    const dispatch = useDispatch();

    const showToast = (message: string, title: string) => {
        const toast = { id: Date.now(), title, message };
        dispatch(addToast(toast));
    };

    const importTrades = async () => {
        setIsLoading(true);
        await importAllTrades(localTrades);
        setTimeout(()=> {
            setIsLoading(false);
            showToast('Trades Imported Successfully!', 'Import Trades');
            setLocalTrades([]);
        }, 1000);
    }

    const deleteAll = () => {
       setLocalTrades([]);
    }


    const handleImageChange = async (e: any) => {
        setIsLoading(true);
        const image = e.target.files[0];
        const formData = new FormData();

        if (image) {
            // @ts-ignore
            formData.append('tradeReport', image, image.name);
        }
        await createTrade(formData);
        setIsLoading(false);
    };

    useEffect(() => {
        if (data && data.trades) {
            setLocalTrades([...data.trades]);
        }
    }, [data]);

    const handleDeleteRow = (rowIndex: any) => {
        const updatedTrades = [...localTrades];
        updatedTrades.splice(rowIndex, 1);
        setLocalTrades(updatedTrades);
    };


    return (
        <Layout>
            {!localTrades.length && (
                <>
                    <h2 className='mt-3'>Import trades</h2>
                    <div className='col-12 mt-2'>
                        <label className="form-label text-white">Drag exported record from meta trader</label>
                        <input type="file" onChange={handleImageChange} className="form-control" />
                    </div>
                    <div className="mt-4 text-white">
                        <h4>How to Export Trades from MetaTrader:</h4>
                        <ol>
                            <li>Open the MetaTrader platform on your device.</li>
                            <li>Navigate to the <strong className='text'>History Tab</strong>.</li>
                            <li>Select the date range for the trades you wish to import.</li>
                            <li>
                                Generate a Report:
                                <ul>
                                    <li>Right-click within the History area.</li>
                                    <li>From the dropdown menu, select <strong>Report</strong>.</li>
                                </ul>
                            </li>
                            <li>After generating the report, choose <strong>Open XML</strong> to export your trades in XML format.</li>
                        </ol>
                        <p>Now, you have successfully exported your trades from MetaTrader! You can proceed to upload the XML file to our platform.</p>
                        <img src={importImage} alt="How to import trades"/>
                    </div>
                    <h3 className='text-danger mt-3'>{isError && 'Invalid file type!'}</h3>
                </>
            )}
            <div>

                <DataLoadHandler isLoading={isLoading}>
                    {localTrades.length && (
                        <div className='import-trades mt-5'>
                            <Button variant='danger' onClick={deleteAll}>
                                Delete All
                            </Button>
                            <Button variant='primary' onClick={importTrades}>
                                Import trades
                            </Button>
                        </div>
                    )}
                    {localTrades.length && (
                        <div className="table-container">
                            <Table className="table" striped bordered hover variant="dark">
                                <thead>
                                <tr>
                                    <th>Pair</th>
                                    <th>Entry Price</th>
                                    <th>Closed Price</th>
                                    <th>Stop Loss</th>
                                    <th>Take Profit</th>
                                    <th>Buy</th>
                                    <th>Sell</th>
                                    <th>created_at Opened</th>
                                    <th>created_at Closed</th>
                                    <th>Commission</th>
                                    <th>Lot Size</th>
                                    <th>Profit</th>
                                    <th>Delete</th>
                                </tr>
                                </thead>
                                <tbody>
                                {localTrades?.map((trade: any, index: any) => (
                                    <tr key={trade.created_at}>
                                        <td>{trade.pair}</td>
                                        <td>{trade.entry_price}</td>
                                        <td>{trade.closed_price}</td>
                                        <td>{trade.stop_loss}</td>
                                        <td>{trade.closed_price}</td>
                                        <td>{trade.take_profit}</td>
                                        <td>{trade.buy ? 'Buy' : 'Sell'}</td>
                                        <td>{trade.created_at}</td>
                                        <td>{trade.dateClosed}</td>
                                        <td>{trade.commission}</td>
                                        <td>{trade.lot_size}</td>
                                        <td>{trade.profit}</td>
                                        <td><button className='delete-button' onClick={() => handleDeleteRow(index)}>Delete</button></td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    )
                    }
                </DataLoadHandler>
            </div>
        </Layout>
    );
}

export default ImportTrades;
