import TimeSeriesChart from "../components/charts/timeserieschart";
import PairsAndWinRate from "../components/PairsAndWinRate";
import ProfitPipsToggle from "../components/charts/ProfitPipsToggle";
import ProfitPipsToggleChart from "../components/charts/ProfitPipsToggleChart";


export const gradients = ['red, purple', 'purple, blue', 'yellow, red'];

export const getDashboardConfig = (data: any) => {
    const {
        pipsByDate, profitByDate, candleStickChartPipsData,
        profitDaily, pipsDaily,
        candleStickChartProfitData, tradesByDate, chartPairData,
        chartPairWinRateData
    } = data || {};

    return [
        {
            title: 'Pairs Traded & Win Rate by Pair',
            component: PairsAndWinRate,
            props: {
                chartPairData: chartPairData,
                winRateData: chartPairWinRateData,
                gradients: gradients
            },
            className: "col-md-6"
        },
        {
            title: 'Trades per Day',
            component: TimeSeriesChart,
            props: { series: [{ data: tradesByDate }]},
            className: "col-md-6"
        },
        {
            title: 'Analysis per Time',
            component: ProfitPipsToggleChart,
            props: {
                pipsTimeSeriesData: [{ data: pipsByDate }],
                pipsCandlestickData: candleStickChartPipsData,
                profitTimeSeriesData: [{ data: profitByDate }],
                profitCandlestickData: candleStickChartProfitData
            },
            className: "col-md-6"
        },
        {
            title: 'Analysis by each day',
            component: ProfitPipsToggle,
            props: {
                profitData: profitDaily,
                pipsData: pipsDaily
            },
            className: "col-md-6"
        },
    ];
}
