import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APP_API_BASE } from './constants';
import { prepareHeaders } from '../utils/apiUtils';

export const balanceApi = createApi({
    reducerPath: 'balanceHistory',
    baseQuery: fetchBaseQuery({
        baseUrl: APP_API_BASE,
        prepareHeaders: prepareHeaders,
    }),
    endpoints: (builder) => ({
        updateBalance: builder.mutation({
            query: (tip) => ({
                url: 'balance-history',
                method: 'POST',
                body: tip
            }),
        }),
        getBalance: builder.query({
            query: (tip) => ({
                url: 'balance-history',
                method: 'GET',
            }),
        }),
        getBalanceHistory: builder.query({
            query: (tip) => ({
                url: 'balance-history/history',
                method: 'GET',
            }),
        }),
        getMonthlyBalance: builder.query({
            query: (tip) => ({
                url: 'balance-history/monthly-increase',
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useUpdateBalanceMutation,
    useGetBalanceQuery,
    useGetBalanceHistoryQuery,
    useGetMonthlyBalanceQuery
} = balanceApi;
