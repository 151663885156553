import React from 'react';
import ApexCharts from 'react-apexcharts';

const DistributedColumnChart = ({ categories, series, chartTitle, colorCondition }: any) => {
    const defaultColors = [
        '#1f77b4', '#d62728', '#ff7f0e', '#ffbb78', '#2ca02c',
        '#98df8a', '#aec7e8', '#ff9896', '#9467bd', '#c5b0d5',
        '#8c564b', '#c49c94'
    ];

    let colors: string[] = [];

    if (series.length === 1 && !colorCondition) {
        colors.push(...series[0].data.map((value: any) => value >= 0 ? defaultColors[0] : defaultColors[1]));
    } else if (series.length > 1) {
        colors = defaultColors;
    }

    const chartOptions: any = {
        chart: {
            type: 'bar',
            foreColor: 'white'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                distributed: true,
                dataLabels: {
                    position: 'top'
                }
            }
        },
        colors: colors,
        dataLabels: {
            enabled: true
        },
        xaxis: {
            categories: categories,
        },
        title: {
            text: chartTitle,
            align: 'left',
        },
        legend: {
            position: 'left'
        },
        tooltip: {
            theme: 'dark'
        }
    };

    return (
        <ApexCharts height={500} options={chartOptions} series={series} type="bar" />
    );
};

export default DistributedColumnChart;
