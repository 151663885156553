import React, {useEffect, useState} from 'react';
import {
    useGetAvailableMarketPairsQuery,
    useGetDailyMarketJournalQuery,
    useGetHourlyMarketJournalQuery,
    useGetMonthlyMarketJournalQuery
} from "../../services/market-journal";
import Layout from "../../components/layout";
import {Form, Table } from "react-bootstrap";
import DistributedColumnChart from "../../components/charts/DistributedColumnChart";
import {useNavigate} from "react-router-dom";
import DateRangePickerComponent from "../../components/DateRangePickerComponent";
import moment from "moment/moment";

interface MarketJournalProps {
    queryFunction: typeof useGetDailyMarketJournalQuery | typeof useGetHourlyMarketJournalQuery | typeof useGetMonthlyMarketJournalQuery;
    title: string;
    chartTitle: string;
    type: string;
}

const MarketJournal: React.FC<MarketJournalProps> = ({ queryFunction, title, chartTitle , type}) => {
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days'));
    const [sendStartDate, setSendStartDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment());
    const [sendEndDate, setSendEndDate] = useState(moment().format('YYYY-MM-DD'));
    const { data: pairsData } = useGetAvailableMarketPairsQuery('');
    const [selectedPair, setSelectedPair] = useState<string>('');
    const {data} = queryFunction({pair: selectedPair, startDate: sendStartDate, endDate: sendEndDate});
    const navigate = useNavigate();

    const handleDatesChange = ({ startDate: startDateValue, endDate: endDateValue }: any) => {
        setStartDate(startDateValue);
        setSendStartDate(moment(startDateValue).format('YYYY-MM-DD'));
        setEndDate(endDateValue);
        setSendEndDate(moment(endDateValue).format('YYYY-MM-DD'));
    };

    useEffect(() => {
        if (pairsData && pairsData.length > 0 && startDate && endDate) {
            setSelectedPair(pairsData[0]);
        }
    }, [pairsData]);

    const handlePairChange = (event: any) => {
        setSelectedPair(event.target.value);
    };

    const navigateToProfile = (type: string, value: string) => {
        navigate(`/market-journal-profile/${type}/${value}?startDate=${sendStartDate}&endDate=${sendEndDate}&pair=${selectedPair}`);
    };

    const categories = data?.chart?.categories;
    const chartData = [
        {
            name: "Average Pips",
            data: data?.chart?.avgPips
        },
        {
            name: "Fake Outs",
            data: data?.chart?.fakeOuts
        },
        {
            name: "Affected By News",
            data: data?.chart?.affectedByNews
        },
        {
            name: "Liquidity grabs",
            data: data?.chart?.liquidityGrabs
        },
        {
            name: "Move Continuation",
            data: data?.chart?.moveContinuations
        },
    ];

    return (
        <Layout>
            <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
                <h2>{title}</h2>
                <div className="d-flex align-items-center">
                    {pairsData && (
                        <Form.Control
                            as="select"
                            value={selectedPair}
                            onChange={handlePairChange}
                            style={{ width: '150px', marginRight: '10px' }}
                        >
                            {pairsData.map((pair: any) => (
                                <option key={pair} value={pair}>{pair}</option>
                            ))}
                        </Form.Control>
                    )}
                    <DateRangePickerComponent
                        startDate={startDate}
                        endDate={endDate}
                        onDatesChange={handleDatesChange}
                    />
                </div>
            </div>

            {categories && chartData && (
                <DistributedColumnChart
                    categories={categories}
                    series={chartData}
                    colorCondition={false}
                    chartTitle={chartTitle}
                />
            )}
            <div className="table-container">
                <Table className="table mt-3" striped bordered hover variant='dark'>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Average Pips</th>
                        <th>Fake Outs</th>
                        <th>Affected By News</th>
                        <th>Liquidity grabs</th>
                        <th>Move Continuation</th>
                        <th>Details</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data?.analyticsData.map((data: any) => (
                        <tr key={data.name}>
                            <td>{data.name}</td>
                            <td>{data.avgPips}</td>
                            <td>{data.fakeOuts}</td>
                            <td>{data.affectedByNews}</td>
                            <td>{data.liquidityGrabs}</td>
                            <td>{data.moveContinuations}</td>
                            <td style={{cursor: 'pointer'}} onClick={() => navigateToProfile(type, data.name)}>View Details</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </Layout>
    );
}

export default MarketJournal;
