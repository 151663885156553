import React, { useState } from 'react';
import {useForgotPasswordMutation} from "../../services/auth";
import '../../styles/components/login.scss';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { FloatingLabel, Form as BSForm } from 'react-bootstrap';
import LoadingButton from "../../components/buttons/loadingbuttuon";
import AuthContainer from "../../components/authContainer";
import {useNavigate} from "react-router-dom";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [callback, setCallback] = useState(() => () => {});
    const [sendEmail, { data, isError }] = useForgotPasswordMutation();
    const validationSchema = yup.object().shape({
        email: yup.string().email('Invalid email format').required('Email is required')
    });

    const handleSubmit = async (values: any, callback: () => void) => {
        try {
            await sendEmail({email: values.email});
        } catch (error) {
            console.error(error);
        } finally {
            callback();
        }
    };

    return (
        <AuthContainer>
            {data && <h2 className='text-white'>{data.message}</h2>}
            {!data && (
                <>
                    <h2 className='mb-2'>Forgot password</h2>
                    <p className='text-white'>Enter your email to reset your password</p>
                    <Formik
                        initialValues={{ email: '' }}
                        validationSchema={validationSchema}
                        onSubmit={(values: any) => {
                            handleSubmit(values, callback);
                        }}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              values,
                              touched,
                              errors,
                              validateForm
                          }) => (
                            <Form>
                                <FloatingLabel controlId="email" label="Email" className="mb-3">
                                    <BSForm.Control
                                        type="text"
                                        value={values.email}
                                        onChange={handleChange('email')}
                                        isInvalid={touched.email && !!errors.email}
                                    />
                                    <BSForm.Control.Feedback type="invalid">
                                        {errors.email}
                                    </BSForm.Control.Feedback>
                                </FloatingLabel>
                                {isError && <p className='text-danger'>User with this email does not exist</p>}
                                <div className="links">
                                    <a onClick={()=> navigate("/login")}>Login</a>
                                    <a onClick={()=> navigate("/register")}>Register</a>
                                </div>
                                <LoadingButton variant="primary" type="submit" size="lg" text="Reset Password" onClickCallback={(submitCallback) => {
                                    validateForm().then((v: any) => {
                                        for (let value in values) {
                                            if (v[value] !== undefined) {
                                                submitCallback();
                                                return;
                                            }
                                        }
                                        setCallback(() => submitCallback);
                                        handleSubmit();
                                    });
                                }}/>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </AuthContainer>
    );
};

export default ForgotPassword;
