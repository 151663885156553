import {createBrowserRouter} from "react-router-dom";
import React from "react";
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import ForgotPassword from "./pages/auth/forgot-password";
import ResetPassword from "./pages/auth/reset-password";
import AccountActivated from "./pages/auth/account-acctivated";
import Trades from "./pages/trades";
import Profile from "./pages/auth/profile";
import Dashboard from "./pages/dashboard";
import ImportTrades from "./pages/import-trades";
import HourlyAnalysis from "./pages/reports/hourly.analysis";
import DayAnalysis from "./pages/reports/day.analysis";
import MonthlyAnalysis from "./pages/reports/monthly.analysis";
import HoldTimeAnalysis from "./pages/reports/hold-time.analysis";
import ByPairAnalysis from "./pages/reports/byPair.analysis";
import ReportProfile from "./pages/reports/report-profile";
import DailyMarketJournal from "./pages/market-journal/DailyMarketJournal";
import HourlyMarketJournal from "./pages/market-journal/HourlyMarketJournal";
import MonthlyMarketJournal from "./pages/market-journal/MonthlyMarketJournal";
import MarketJournalDashboard from "./pages/market-journal/JournalDashboard";
import MarketJournalProfile from "./pages/market-journal/MarketJournalProfile";
import TradeCalendar from "./pages/trade-calendar";
import TradingPlan from "./pages/trading-plan";
import GroupedTrades from "./pages/grouped-trades";
import Feedback from "./pages/Feedback";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />
    },
    {
        path: "/dashboard",
        element: <Dashboard />
    },
    {
        path: "/trading-plan",
        element: <TradingPlan />
    },
    {
        path: "/report/profile",
        element: <ReportProfile />
    },
    {
        path: "/reports/hold-time-analysis",
        element: <HoldTimeAnalysis />
    },
    {
        path: "/reports/by-pair-analysis",
        element: <ByPairAnalysis />
    },
    {
        path: "/reports/hourly-analysis",
        element: <HourlyAnalysis />
    },
    {
        path: "/reports/daily-analysis",
        element: <DayAnalysis />
    },
    {
        path: "/reports/monthly-analysis",
        element: <MonthlyAnalysis />
    },
    {
        path: "/market-journal/monthly",
        element: <MonthlyMarketJournal />
    },
    {
        path: "/market-journal/daily",
        element: <DailyMarketJournal />
    },
    {
        path: "/market-journal/hourly",
        element: <HourlyMarketJournal />
    },
    {
        path: "/market-journal-profile/:type/:value",
        element: <MarketJournalProfile />
    },
    {
        path: "/market-journal/dashboard",
        element: <MarketJournalDashboard />
    },
    {
        path: "/trades",
        element: <Trades />
    },
    {
        path: "/grouped-trades",
        element: <GroupedTrades />
    },
    {
        path: "/calendar",
        element: <TradeCalendar />
    },
    {
        path: "/imported-trades",
        element: <ImportTrades />
    },
    {
        path: "/feedback",
        element: <Feedback />
    },
    {
        path: "/register",
        element: <Register/>,
    },
    {
        path: "/login",
        element: <Login/>
    },
    {
        path: "/profile",
        element: <Profile/>
    },
    {
        path: "/auth/activate/:token",
        element: <AccountActivated/>
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword/>
    },
    {
        path: "/reset-password/:token",
        element: <ResetPassword/>
    }
]);
