import React from 'react';
import TimeSeriesChart from "../../components/charts/timeserieschart";
import {useGetReportProfileQuery} from "../../services/reports";
import {Table} from "react-bootstrap";
import Layout from "../../components/layout";
import {useLocation} from "react-router-dom";
import '../../styles/components/trades.scss';
import {
    BsGraphUp,
    BsExclamationTriangle,
    BsPercent,
    BsCurrencyDollar
} from 'react-icons/bs';
import StatCard from "../../components/statcard";
import moment from "moment";

const ReportProfile = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const reportType = queryParams.get('reportType');
    const value = queryParams.get('value');
    const startDate = queryParams.get('startDate');
    const endDate = queryParams.get('endDate');

    const { data } = useGetReportProfileQuery({ reportType: reportType, value: value, startDate, endDate});

    const statsConfig = [
        { title: "Number of Trades", data: data?.numberOfTrades, IconComponent: BsGraphUp, bg: "light", textColor: "black" },
        { title: "Profitable Trades", data: data?.profitableTrades, IconComponent: BsGraphUp, bg: "light", textColor: "black" },
        { title: "Losing Trades", data: data?.losingTrades, IconComponent: BsExclamationTriangle, bg: "light", textColor: "black" },
        { title: "Biggest Loss", data: `$ ${data?.biggestLoss}`, IconComponent: BsCurrencyDollar, bg: "light", textColor: "black" },
        { title: "Biggest Win", data: `$ ${data?.biggestWin}`, IconComponent: BsCurrencyDollar, bg: "light", textColor: "black" },
        { title: "Total Profit", data: `$ ${data?.totalProfit}`, IconComponent: BsCurrencyDollar, bg: "light", textColor: "black" },
        { title: "Total Commission", data: `$ ${data?.totalCommission}`, IconComponent: BsCurrencyDollar, bg: "light", textColor: "black" },
        { title: "Win Rate", data: `${data?.winRate} %`, IconComponent: BsPercent, bg: "light", textColor: "black" },
        { title: "Biggest % Gain ", data: `${data?.biggestGain.percentage} %`, IconComponent: BsPercent, bg: "light", textColor: "black" },
        { title: "Max DrawDown %", data: `${data?.biggestDrawdown.percentage} %`, IconComponent: BsPercent, bg: "light", textColor: "black" },
        { title: "Biggest $ Gain", data: `${data?.biggestGain.value} $`, IconComponent: BsCurrencyDollar, bg: "light", textColor: "black" },
        { title: "Max DrawDown $", data: `${data?.biggestDrawdown.value} $`, IconComponent: BsCurrencyDollar, bg: "light", textColor: "black" },
        { title: "Avg Loss On Long", data: data?.avgLossOnLong, IconComponent: BsCurrencyDollar, bg: "light", textColor: "black" },
        { title: "Avg Loss On Short", data: data?.avgLossOnShort, IconComponent: BsCurrencyDollar, bg: "light", textColor: "black" },
        { title: "Avg Win On Long", data: data?.avgWinOnLong, IconComponent: BsCurrencyDollar, bg: "light", textColor: "black" },
        { title: "Avg Win On Short", data: data?.avgWinOnShort, IconComponent: BsCurrencyDollar, bg: "light", textColor: "black" },
    ];

    return (
        <Layout>
            <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
                <h2>{value} {reportType ==='hours' && 'h'} Analysis</h2>
            </div>
            <div className="row">
                {statsConfig.map((stat, index) => (
                    <StatCard key={index} lgValue={3} {...stat} />
                ))}
            </div>

            {data?.profitPerDay.length &&
                <TimeSeriesChart series={[{ data: data.profitPerDay }]} chartTitle='Profit per each traded date'/>
            }
            <h2>Insights</h2>
            <div className="insight-container mt-4">
                {data?.insights.map((insight: string, index: number) => (
                    <div className="insight-card" key={index}>
                        <div className="insight-header">
                            <h5>Insight {index + 1}</h5>
                        </div>
                        <div className="insight-body">
                            {insight}
                        </div>
                    </div>
                ))}
            </div>
            <h2>List of all trades</h2>
            <div className="table-container">
                <Table className="table mt-3" striped bordered hover variant='dark'>
                    <thead>
                    <tr>
                        <th>Date Time</th>
                        <th>Pair</th>
                        <th>Profit</th>
                        <th>Type</th>
                        <th>Lot</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data?.trades.map((data: any) => (
                        <tr key={data.name}>
                            <td>{moment(data.date_closed).format('DD-MM-YYYY:HH:MM')}</td>
                            <td>{data.pair}</td>
                            <td>{data.profit}</td>
                            <td>{data.buy ? 'Buy' : 'Sell'}</td>
                            <td>{data.lot_size}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </Layout>
    );
};

export default ReportProfile;
