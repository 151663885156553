import React from 'react';
import PieChart from "./charts/piechart";
import WinRateContainer from "./charts/winratecontainer";

const PairsAndWinRate = ({ chartPairData, winRateData, gradients }: any) => {
    return (
        <>
            <PieChart chartPairData={chartPairData} />
            <WinRateContainer data={winRateData} gradients={gradients} />
        </>
    );
};

export default PairsAndWinRate;
