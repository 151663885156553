import {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useCreateTradeMutation, useUpdateTradeMutation} from "../services/trades";
import {Rule, TradeModalProps} from "../models/trade";
import {useDispatch} from "react-redux";
import {addToast} from "../store/slices/toast";
import TextInput from "./inputs/input";
import TextArea from "./inputs/textarea";
import Checkbox from "./inputs/checkbox";
import ImageInput from "./inputs/imageinput";
import DateTimePicker from "react-datetime-picker";
import {useGetTradingPlanQuery} from "../services/trading_plan";

function TradeModal({ view = false, initialTrade, buttonTitle, onTradeSaved, buttonClass = '', rules}: TradeModalProps) {
    const [dateOpened, setDateOpened] = useState(new Date())
    const [dateClosed, setDateClosed] = useState(new Date())
    const [mode, setMode] = useState<'Create' | 'Edit'>('Create');
    const registerState: any = localStorage.getItem('token');
    const userId = JSON.parse(registerState).id;
    const [createTrade] = useCreateTradeMutation();
    const [updateTrade] = useUpdateTradeMutation();
    const [buy, setBuy] = useState(true);
    const [sell, setSell] = useState(false);
    const [news, setNews] = useState(false);
    const [entryPrice, setEntryPrice] = useState(0);
    const [closedPrice, setClosedPrice] = useState(0);
    const [stopLoss, setStopLoss] = useState(0);
    const [takeProfit, setTakeProfit] = useState(0);
    const [tradeReason, setTradeReason] = useState("");
    const [pair, setPair] = useState("");
    const [image, setImage] = useState(null);
    const [show, setShow] = useState(false);
    const [profit, setProfit] = useState(0);
    const [pips, setPips] = useState(0);
    const [lot, setLot] = useState(0);
    const [selectedTrend, setSelectedTrend] = useState('');
    const [mistakeDescription, setMistakeDescription] = useState("");
    const [couldDoneBetter, setCouldDoneBetter] = useState("");
    const [selectedRuleId, setSelectedRuleId] = useState<number>(0);

    const { refetch: updateBalance } = useGetTradingPlanQuery('');

    const trends = ['Bullish', 'Bearish'];

    const dispatch = useDispatch();

    const showToast = (message: string, title: string) => {
        const toast = { id: Date.now(), title, message };
        dispatch(addToast(toast));
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleImageChange = (e: any) => {
        setImage(e.target.files[0]);
    };

    const closeModal = () => {
        onTradeSaved();
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();


        const formData = new FormData();

        const tradeData: { [key: string]: any } = {
            buy: buy,
            sell: sell,
            entry_price: Number(entryPrice),
            closed_price: Number(closedPrice),
            stop_loss: Number(stopLoss),
            take_profit: Number(takeProfit),
            trade_reason: tradeReason,
            pair,
            userId: userId,
            date_opened: dateOpened,
            date_closed: dateClosed,
            ruleId: selectedRuleId,
            profit: Number(profit),
            pips: Number(pips),
            lot_size: Number(lot),
            trend: selectedTrend,
            mistakeDescription: mistakeDescription,
            couldDoneBetter: couldDoneBetter,
        };

        for (let key in tradeData) {
            formData.append(key, tradeData[key]);
        }

        if (image) {
            // @ts-ignore
            formData.append('image', image, image.name);
        }
        if (mode === 'Create') {
            await createTrade(formData);
            updateBalance();
            showToast('Create trade', 'Trade created successfully!')
        } else {
            await updateTrade({trade: formData, tradeId: initialTrade?.id});
            updateBalance();
            showToast('Edit trade', 'Trade edited successfully!')
        }
        closeModal();
        handleClose();
    };

    useEffect(() => {
        if (initialTrade) {
            setMode('Edit');
            setBuy(initialTrade.buy);
            setSell(initialTrade.sell);
            setEntryPrice(initialTrade.entry_price);
            setClosedPrice(initialTrade.closed_price);
            setStopLoss(initialTrade.stop_loss);
            setTakeProfit(initialTrade.take_profit);
            setProfit(initialTrade.profit);
            setPips(initialTrade.pips);
            setPair(initialTrade.pair);
            setLot(initialTrade.lot_size);
            setDateOpened(initialTrade.date_opened);
            setDateClosed(initialTrade.date_closed);
            setSelectedRuleId(initialTrade.ruleId ? initialTrade.ruleId : 0);
            setMistakeDescription(initialTrade.mistakeDescription);
            setCouldDoneBetter(initialTrade.couldDoneBetter);
            setTradeReason(initialTrade.trade_reason);
        } else {
            setMode('Create');
        }
    }, [initialTrade]);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                {buttonTitle}
            </Button>
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{mode === 'Create' ? 'Create new' : 'Edit'} trade</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="container">
                        <div className="row mb-3">
                            <div className="col-lg-3 col-md-6">
                                <label className="form-label">Trade opened</label>
                                <DateTimePicker
                                    disabled={view}
                                    onChange={(date: any) => setDateOpened(date)}
                                    value={dateOpened}
                                    className="my-datetime-picker"
                                />
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <label className="form-label">Trade closed</label>
                                <DateTimePicker
                                    disabled={view}
                                    onChange={(date: any) => setDateClosed(date)}
                                    className="my-datetime-picker"
                                    value={dateClosed} />
                            </div>
                            <div className="col-md-2">
                                <TextInput disabled={view} type="text" value={pair} onChange={(val: any) => setPair(val)} placeholder="Pair" label="Pair" />
                            </div>
                            <div className="col-md-2">
                                <TextInput disabled={view} type="number" value={profit} onChange={(val: any) => setProfit(val)} placeholder="$" label="Profit $" />
                            </div>
                            <div className="col-md-2">
                                <TextInput disabled={view} type="number" value={pips} onChange={(val: any) => setPips(val)} placeholder="Pips" label="Pips" />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-2">
                                <TextInput disabled={view} type="number" value={entryPrice} onChange={(val: any) => setEntryPrice(val)} placeholder="Entry Price" label="Entry Price" />
                            </div>
                            <div className="col-md-2">
                                <TextInput disabled={view} type="number" value={closedPrice} onChange={(val: any) => setClosedPrice(val)} placeholder="Closed Price" label="Closed Price" />
                            </div>
                            <div className="col-md-2">
                                <TextInput disabled={view} type="number" value={stopLoss} onChange={(val: any) => setStopLoss(val)} placeholder="Stop Loss" label="Stop Loss" />
                            </div>
                            <div className="col-md-2">
                                <TextInput disabled={view} type="number" value={takeProfit} onChange={(val: any) => setTakeProfit(val)}  placeholder="Take Profit" label="Take Profit" />
                            </div>
                            <div className="col-md-2">
                                <TextInput disabled={view} type="number" value={lot} onChange={(val: any) => setLot(val)} placeholder="Lot" label="Lot" />
                            </div>
                            <div className="col-md-2">
                                <label className="form-label">Trend</label>
                                <select
                                    disabled={view}
                                    className="form-control"
                                    value={selectedTrend || ""}
                                    onChange={(e) => setSelectedTrend(e.target.value)}
                                >
                                    <option value="" disabled>Select a trend...</option>
                                    {trends?.map((trend: string) => (
                                        <option key={trend} value={trend}>{trend}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <label className="form-label">Select Setup</label>
                                <select
                                    disabled={view}
                                    className="form-control"
                                    value={selectedRuleId || ""}
                                    onChange={(e) => setSelectedRuleId(Number(e.target.value))}
                                >
                                    <option value="" disabled>Select a setup...</option>
                                    {rules?.map((rule: Rule) => (
                                        <option key={rule.id} value={rule.id}>{rule.rule}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-6">
                                <TextInput disabled={view} type="text" value={mistakeDescription} onChange={(val: any) => setMistakeDescription(val)} placeholder="Mistake Reason" label="Mistake" />
                            </div>
                            <div className="col-6">
                                <TextInput disabled={view} type="text" value={couldDoneBetter} onChange={(val: any) => setCouldDoneBetter(val)} placeholder="Could Have Done Better" label="Could Have Done Better" />
                            </div>
                        </div>
                        <div className="mb-12">
                            <div className="col-md-12">
                                <TextArea disabled={view} value={tradeReason} onChange={setTradeReason} placeholder="Trade Reason" label="Trade Reason" />
                            </div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <ImageInput disabled={view} image={initialTrade?.image} onChange={handleImageChange} />
                        </div>
                        <div className="mb-3">
                            <Checkbox disabled={view} checked={buy} onChange={() => { setBuy(!buy); setSell(buy)}} label="Buy" />
                            <Checkbox disabled={view} checked={sell} onChange={() => { setSell(!sell); setBuy(sell)}} label="Sell" />
                            <Checkbox disabled={view} checked={news} onChange={() => { setNews(!news)}} label="Is affected by news" />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {!view &&
                        <Button variant="primary" onClick={handleSubmit}>
                            {mode} trade
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default TradeModal;
