import React, { useState } from 'react';
import CandlestickChart from "./candlestickchart";
import TimeSeriesChart from "./timeserieschart";
import {Form} from "react-bootstrap";

function ToggleableChart({ timeSeriesData, candlestickData, title }: any) {
    const [showCandlestick, setShowCandlestick] = useState(true);

    return (
        <>
            <Form>
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={showCandlestick ? 'Candlestick Chart' : 'Line Chart'}
                    onClick={() => setShowCandlestick(!showCandlestick)}

                />
            </Form>
            {showCandlestick ? (
                <CandlestickChart candlestickData={candlestickData} />
            ) : (
                <TimeSeriesChart series={timeSeriesData} />
            )}
        </>
    );
}

export default ToggleableChart;
