import React, { useState } from 'react';
import TimeSeriesChart from "./timeserieschart";
import {Form} from "react-bootstrap";

const ProfitPipsToggle = ({ profitData, pipsData }: any) => {
    const [showProfit, setShowProfit] = useState(true);

    return (
        <>
            <Form>
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={showProfit ? 'By Profit' : 'By Pips'}
                    onClick={() => setShowProfit(!showProfit)}

                />
            </Form>
            {showProfit ? (
                <TimeSeriesChart series={[{ data: profitData }]} />
            ) : (
                <TimeSeriesChart series={[{ data: pipsData }]} />
            )}
        </>
    );
};

export default ProfitPipsToggle;
