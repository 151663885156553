import React from 'react';
import {useGetDailyMarketJournalQuery} from "../../services/market-journal";
import MarketJournal from "./MarketJournal";

const DailyMarketJournal = () => {

    return (
        <MarketJournal
            queryFunction={useGetDailyMarketJournalQuery}
            title="Daily Market Journal Analysis"
            chartTitle="Daily Market Journal Analysis Overview"
            type='day'
        />
    );
}

export default DailyMarketJournal;
