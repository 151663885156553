import React from 'react';
import ApexCharts from 'react-apexcharts';

const CandlestickChart = ({ candlestickData }: any) => {
    const allYValues = candlestickData.flatMap((entry: any) => entry.y);

    const dataMax = Math.max(...allYValues);
    const dataMin = Math.min(...allYValues);

    const padding = 200;
    const yaxisMax = dataMax + padding;
    const yaxisMin = dataMin - padding;

    const options: any = {
        chart: {
            type: 'candlestick',
            height: 350,
            foreColor: 'white',
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true,
                zoomedArea: {
                    fill: {
                        color: '#90CAF9',
                        opacity: 0.4
                    },
                    stroke: {
                        color: '#0D47A1',
                        opacity: 0.4,
                        width: 1
                    }
                }
            },
            toolbar: {
                autoSelected: 'zoom'
            },
        },
        title: {
            text: 'Candlestick Pips Chart',
            align: 'left',
        },
        xaxis: {
            type: 'datetime',
        },
        yaxis: {
            min: yaxisMin,
            max: yaxisMax,
            tooltip: {
                enabled: true,
            },
            labels: {
                formatter: function (val: number) {
                    return val.toFixed(2);
                }
            }
        },
        tooltip: {
            theme:'dark'
        }
    };

    return (
        <ApexCharts options={options} series={[{ data: candlestickData }]} type="candlestick" height={350} />
    );
};

export default CandlestickChart;
