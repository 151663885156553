import React from 'react';
import { Modal } from 'react-bootstrap';
import TradeCard from "./tradecart";
import {useTradingPlan} from "../hooks/trading-plan.hook";

interface TradeModalProps {
    show: boolean;
    trades: any[];
    onHide: () => void;
}

const TradeModal: React.FC<TradeModalProps> = ({ show, trades, onHide }) => {
    const { rules } = useTradingPlan();
    return (
        <Modal show={show} onHide={onHide} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>List of Trades</Modal.Title>
            </Modal.Header>
            <Modal.Body className="trade-modal-body">
                {trades?.map((trade) => (
                    <TradeCard
                        key={trade.id}
                        trade={trade}
                        handleImageClick={() => {}}
                        openDeleteModal={() => {}}
                        handleCloseModal={() => {}}
                        rules={rules}
                        showActions={false}
                    />
                ))}
            </Modal.Body>
        </Modal>
    );
};

export default TradeModal;
