import React, {useMemo, useState} from 'react';
import {Button, Table} from 'react-bootstrap';
import moment from "moment";
import TradeModal from "../components/modal";
import {Rule, Trade} from "../models/trade";
import { MultiSelect } from 'react-multi-select-component';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';


type TradeSummaryTableProps = {
    tradingPlanSummary: Trade[];
    handleImageClick: (image: string | null) => void;
    openDeleteModal: (tradeId: number) => void;
    handleCloseModal: () => void;
    rules: Rule[];
};

type FieldDefinition = {
    key: any;
    label: string;
};

const ImageCellRenderer = (props: any) => {
    return props.value
        ? <img
            src={props.value}
            style={{ maxHeight: '50px' }}
            alt="Trade Image"
            onClick={() => props.context.handleImageClick(props.value)}
        />
        : '-';
}

const EditButtonCellRenderer = (props: any) => {
    return (
        <TradeModal
            buttonTitle="Edit"
            initialTrade={props.data}
            onTradeSaved={props.context.handleCloseModal}
            rules={props.context.rules}
        />
    );
}

const DeleteButtonCellRenderer = (props: any) => {
    return (
        <Button variant="danger" onClick={() => props.context.openDeleteModal(props.data.id)}>
            x
        </Button>
    );
}

const StatusButtonRenderer = (props: any) => {
    const isWin = props.data.profit > 0;
    return (
        <div style={{
            display: 'inline-block',
            borderRadius: '4px',
            fontWeight: 'bold',
            color: 'white',
            width: '60px',
            backgroundColor: isWin ? 'green' : 'red',
            textAlign: 'center'
        }}>
            {isWin ? 'WIN' : 'LOSS'}
        </div>
    );
}

function defaultFormatter(params: any) {
    return params.value ? params.value : '-';
}

const TradeSummaryTable: React.FC<TradeSummaryTableProps> = ({ tradingPlanSummary, openDeleteModal, rules, handleCloseModal, handleImageClick }) => {
    const columns: any = useMemo(() => [
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            resizable: false,
            cellRenderer: 'statusButtonRenderer'
        },
        { field: 'stop_loss', headerName: 'Stop Loss', sortable: true, filter: false },
        { field: 'closed_price', headerName: 'Closed Price', sortable: true, filter: false },
        {
            field: 'profit',
            headerName: 'Profit',
            cellStyle: (params: any): any => {
                return params.value >= 0 ? { color: 'green' } : { color: 'red' };
            },
            cellRenderer: (params: any): string => {
                return params.value !== undefined ? `$ ${Math.abs(params.value)}` : '-';
            }
        },
        { field: 'entry_price', headerName: 'Entry Price', sortable: true, filter: false },
        {
            field: 'type',
            headerName: 'Type',
            valueFormatter: defaultFormatter,
            cellRenderer: (params: any) => {
                return params.data.buy ? 'Buy' : 'Sell';
            }
        },
        { field: 'take_profit', headerName: 'Take Profit', sortable: true, filter: false, valueFormatter: defaultFormatter },
        { field: 'pair', headerName: 'Pair', sortable: true, filter: false, valueFormatter: defaultFormatter },
        { field: 'trade_reason', headerName: 'Trade Reason', sortable: true, filter: false, valueFormatter: defaultFormatter },
        {
            field: 'image',
            headerName: 'Image',
            cellRenderer: 'imageRenderer'
        },
        { field: 'pips', headerName: 'Pips', sortable: true, filter: false, valueFormatter: defaultFormatter },
        {
            field: 'mistake',
            headerName: 'mistake',
            valueFormatter: defaultFormatter,
            cellRenderer: (params: any) => {
                return params.value ? `YES` : 'NO';
            }
        },
        { field: 'mistakeDescription', headerName: 'Mistake Description', sortable: true, filter: false, valueFormatter: defaultFormatter },
        { field: 'couldDoneBetter', headerName: 'Could Have Done Better', sortable: true, filter: false, valueFormatter: defaultFormatter },
        { field: 'commission', headerName: 'Commission', sortable: true, filter: false, valueFormatter: defaultFormatter },
        { field: 'lot_size', headerName: 'Lot Size', sortable: true, filter: false, valueFormatter: defaultFormatter },
        { field: 'trend', headerName: 'Trend', sortable: true, filter: false, valueFormatter: defaultFormatter },
        {
            field: 'ruleId',
            headerName: 'Setup',
            cellRenderer: (params: any) => {
                return getRuleNameFromId(params.value);
            }
        },
        {
            field: 'date_opened',
            headerName: 'Opened',
            cellRenderer: (params: any) => {
                return params.value ? moment(params.value).format('DD-MM-YYYY HH:mm') : '-';
            }
        },
        {
            field: 'date_closed',
            headerName: 'Closed',
            cellRenderer: params => {
                return params.value ? moment(params.value).format('DD-MM-YYYY HH:mm') : '-';
            }
        },
        {
            headerName: 'Edit',
            cellRenderer: 'editButtonRenderer',
            width: 90,
            sortable: false,
            filter: false
        },
        {
            headerName: 'Delete',
            cellRenderer: 'deleteButtonRenderer',
            width: 75,
            sortable: false,
            filter: false
        }
    ], []);

    const defaultColDef = useMemo(() => ({
        resizable: true,
        sortable: true,
        filter: false,
    }), []);

    const fieldDefinitions: FieldDefinition[] = [
        { key: 'status', label: 'Status' },
        { key: 'entry_price', label: 'Entry Price' },
        { key: 'closed_price', label: 'Closed Price' },
        { key: 'stop_loss', label: 'Stop Loss' },
        { key: 'take_profit', label: 'Take Profit' },
        { key: 'profit', label: 'Profit' },
        { key: 'type', label: 'Type' },
        { key: 'pair', label: 'Pair' },
        { key: 'trade_reason', label: 'Trade Reason' },
        { key: 'image', label: 'Image' },
        { key: 'pips', label: 'Pips' },
        { key: 'mistake', label: 'Mistake' },
        { key: 'mistakeDescription', label: 'Mistake Description' },
        { key: 'couldDoneBetter', label: 'Could Have Done Better' },
        { key: 'commission', label: 'Commission' },
        { key: 'lot_size', label: 'Lot Size' },
        { key: 'trend', label: 'Trend' },
        { key: 'ruleId', label: 'Setup' },
        { key: 'date_opened', label: 'Opened' },
        { key: 'date_closed', label: 'Closed' },
    ];

    const allowedFields: (keyof Trade)[] = fieldDefinitions.map(field => field.key);

    const fieldLabelMap = fieldDefinitions.reduce((acc: any, field: any) => {
        acc[field.key] = field.label;
        return acc;
    }, {} as { [key in keyof Trade]?: string });

    const fieldOptions = fieldDefinitions.map(field => ({
        value: field.key,
        label: field.label,
    }));

    const getRuleNameFromId = (ruleId: number) => {
        const rule = rules?.find(r => r.id === ruleId);
        return rule ? rule.rule : '-';
    };

    const [selectedFields, setSelectedFields] = useState<(keyof Trade)[]>(
        allowedFields.slice(0, 11)
    );

    const handleFieldChange = (selectedOptions: any[]) => {
        setSelectedFields(selectedOptions.map(option => option.value));
    };

    const frameworkComponents: any = {
        imageRenderer: ImageCellRenderer,
        editButtonRenderer: EditButtonCellRenderer,
        deleteButtonRenderer: DeleteButtonCellRenderer,
        statusButtonRenderer: StatusButtonRenderer
    };

    return (
        <>
            <div className="ag-theme-alpine" style={{ height: 1000, width: '100%' }}>
                <AgGridReact
                    context={{
                        handleImageClick: handleImageClick,
                        handleCloseModal: handleCloseModal,
                        openDeleteModal: openDeleteModal,
                        rules: rules
                    }}
                    columnDefs={columns}
                    rowHeight={60}
                    components={frameworkComponents}
                    defaultColDef={defaultColDef}
                    rowData={tradingPlanSummary}
                    // Other grid options
                />
            </div>
            {/*<div className="container">*/}
            {/*    <div className="row">*/}
            {/*        <div className="offset-lg-9 col-lg-3">*/}
            {/*            <h3 className='text-white'>Customize columns</h3>*/}
            {/*            <MultiSelect*/}
            {/*                options={fieldOptions}*/}
            {/*                value={selectedFields.map(field => ({*/}
            {/*                    label: fieldLabelMap[field] ?? field,*/}
            {/*                    value: field*/}
            {/*                }))}*/}
            {/*                onChange={handleFieldChange}*/}
            {/*                labelledBy="Select Fields"*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="table-container trades-container">*/}

            {/*    <Table className="table mt-3" bordered variant="dark">*/}
            {/*        <thead>*/}
            {/*        <tr>*/}
            {/*            {selectedFields.map(field => (*/}
            {/*                <th key={field}>{fieldLabelMap[field] ?? field}</th>*/}
            {/*            ))}*/}
            {/*            <th>Edit</th>*/}
            {/*            <th>Delete</th>*/}
            {/*        </tr>*/}
            {/*        </thead>*/}
            {/*        <tbody>*/}
            {/*        {tradingPlanSummary?.map((trade: any) => (*/}
            {/*            <tr key={trade.id}>*/}
            {/*                {selectedFields.map((field: any) => (*/}
            {/*                    <td*/}
            {/*                        className={*/}
            {/*                            field === 'profit' ? trade.profit >= 0 ? 'text-success' : 'text-danger'  :*/}
            {/*                                field === 'status' ? trade.profit > 0 ? 'bg-success' : 'bg-danger' : ''*/}
            {/*                        }*/}
            {/*                        key={field}>*/}
            {/*                        {field === 'ruleId'*/}
            {/*                            ? getRuleNameFromId(trade[field])*/}
            {/*                            : field === 'status'*/}
            {/*                            ? trade.profit > 0*/}
            {/*                                ? 'WIN'*/}
            {/*                                : 'LOSS'*/}
            {/*                            : field === 'type'*/}
            {/*                            ? trade.buy ? 'Buy' : 'Sell'*/}
            {/*                            : field === 'profit' && trade.profit !== undefined*/}
            {/*                                ? trade.profit >= 0*/}
            {/*                                    ? `$${trade.profit}`*/}
            {/*                                    : `-$${Math.abs(trade.profit)}`*/}
            {/*                                : field === 'opened' || field === 'closed'*/}
            {/*                                    ? moment(trade[field]).format('DD-MM-YYYY-HH:mm')*/}
            {/*                                    : field === 'image' && trade.image*/}
            {/*                                        ? (*/}
            {/*                                            <img*/}
            {/*                                                className="table-image"*/}
            {/*                                                src={trade.image}*/}
            {/*                                                alt="Trade image"*/}
            {/*                                                onClick={() => handleImageClick(trade.image)}*/}
            {/*                                            />*/}
            {/*                                        )*/}
            {/*                                        : trade[field] ? trade[field] : '-'}*/}
            {/*                    </td>*/}
            {/*                ))}*/}
            {/*                <td>*/}
            {/*                    <TradeModal*/}
            {/*                        buttonTitle="Edit"*/}
            {/*                        initialTrade={trade}*/}
            {/*                        onTradeSaved={handleCloseModal}*/}
            {/*                        rules={rules}*/}
            {/*                    />*/}
            {/*                </td>*/}
            {/*                <td>*/}
            {/*                    <Button variant="danger" onClick={() => openDeleteModal(trade.id)}>*/}
            {/*                        x*/}
            {/*                    </Button>*/}
            {/*                </td>*/}
            {/*            </tr>*/}
            {/*        ))}*/}
            {/*        </tbody>*/}
            {/*    </Table>*/}
            {/*</div>*/}
        </>
    );
};

export default TradeSummaryTable;
