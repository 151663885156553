import React, { useState } from 'react';
import ToggleableChart from "./ToggleChart";
import {Form} from "react-bootstrap";

const ProfitPipsToggleChart = ({ pipsTimeSeriesData, pipsCandlestickData, profitTimeSeriesData, profitCandlestickData }: any) => {
    const [showProfit, setShowProfit] = useState(true);

    return (
        <>
            <Form>
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="By Profit/Pips"
                    onClick={() => setShowProfit(!showProfit)}

                />
            </Form>
            {showProfit ? (
                <ToggleableChart timeSeriesData={profitTimeSeriesData} candlestickData={profitCandlestickData} />
            ) : (
                <ToggleableChart timeSeriesData={pipsTimeSeriesData} candlestickData={pipsCandlestickData} />
            )}
        </>
    );
};

export default ProfitPipsToggleChart;
