import ProgressChart from "./progresChart";

const WinRateContainer = ({ data, gradients }: any) => {
    return (
        <div>
            {data?.map((el: any, index: number) => (
                <ProgressChart
                    key={index}
                    title={el.labelsData}
                    percentage={el.seriesData}
                    colorGradient={gradients[index % gradients.length]}
                />
            ))}
        </div>
    );
}

export default WinRateContainer;
