import React from 'react';
import {useGetHourlyMarketJournalQuery} from "../../services/market-journal";
import MarketJournal from "./MarketJournal";

const HourlyMarketJournal = () => {

    return (
        <MarketJournal
            queryFunction={useGetHourlyMarketJournalQuery}
            title="Hourly Market Journal Analysis"
            chartTitle="Hourly Market Journal Analysis Overview"
            type='hour'
        />
    );
}

export default HourlyMarketJournal;
