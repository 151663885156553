import React from 'react';

interface ProgressChartProps {
    title: string;
    percentage: number;
    colorGradient: string;
}

const ProgressChart = ({ title, percentage, colorGradient }: ProgressChartProps) => {
    const progressBarStyle = (colorGradient: string) => ({
        background: `linear-gradient(170deg, ${colorGradient})`,
        borderRadius: '4px',
        height: '18px',
        width: '100%',
        position: 'relative' as 'relative',
    });

    const remainingBarStyle = (percentage: number) => ({
        background: 'lightgrey',
        borderBottomRightRadius: '4px',
        borderTopRightRadius: '4px',
        height: '18px',
        width: `${100 - percentage}%`,
        position: 'absolute' as 'absolute',
        top: 0,
        left: `${percentage}%`,
    });

    return (
        <div style={{ width: '100%', padding: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                <span>{title}</span>
                <span>{percentage}%</span>
            </div>
            <div style={progressBarStyle(colorGradient)}>
                <div style={remainingBarStyle(percentage)}></div>
            </div>
        </div>
    );
};

export default ProgressChart;
