import React from 'react';
import { Card, Col } from 'react-bootstrap';

const StatCard = ({ title, data, IconComponent, bg, textColor = "white", biggerCard =false, lgValue=3 }: any) => {

    return (
        <Col lg={lgValue} md={3} sm={6}>
            <Card bg={bg} text={textColor} className="mb-3 stats-card">
                <Card.Body>
                    <div className="card-content">
                        <div className="text-content">
                            <Card.Title>{title}</Card.Title>
                            <Card.Text>{data}</Card.Text>
                        </div>
                        <IconComponent className="icon" />
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
}

export default StatCard;
