import React, {useState} from 'react';
import Layout from "../components/layout";
import {useGetGroupedTradesQuery, useGetFiltersQuery} from "../services/trades";
import moment from "moment";
import Select from "react-select";
import DateRangePickerComponent from "../components/DateRangePickerComponent";
import TradeModal from "../components/modal";
import {useTradingPlan} from "../hooks/trading-plan.hook";

const GroupedTrades = () => {
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days'));
    const [sendStartDate, setSendStartDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment());
    const [sendEndDate, setSendEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [selectedType, setSelectedType] = useState<any>(null);
    const [selectedSort, setSelectedSort] = useState<any>(null);
    const [selectedPairs, setSelectedPairs] = useState<any>([]);
    const { rules } = useTradingPlan();

    const { data, refetch } = useGetGroupedTradesQuery({startDate: sendStartDate, endDate: sendEndDate, pairs: selectedPairs, sort: selectedSort, type: selectedType});
    const { data: filters } = useGetFiltersQuery({startDate: sendStartDate, endDate: sendEndDate});

    const typeOptions = [
        { value: 'Buy', label: 'Buy' },
        { value: 'Sell', label: 'Sell' }
    ];

    const sortOptions = filters?.sortOptions || [];
    const pairOptions = filters?.pairs?.map((pair: string) => ({ value: pair, label: pair })) || [];

    const handleDatesChange = ({ startDate, endDate }: any) => {
        setStartDate(startDate);
        setSendStartDate(startDate.format('YYYY-MM-DD'));
        setEndDate(endDate);
        setSendEndDate(endDate.format('YYYY-MM-DD'));
        refetch();
    };


    return (
        <Layout>
            <div className="container mt-3">
                <div className="row justify-content-between align-items-center">
                    <div className="col-lg-2 col-md-12 col-12">
                        <h2>Trades by date</h2>
                    </div>
                    <div className="col-lg-10 col-md-12">
                        <div className="row">
                            <div className="col-md-2 offset-md-3 col-sm-6 col-6 my-1">
                                <Select
                                    value={selectedPairs}
                                    onChange={(selectedOptions) => setSelectedPairs(selectedOptions)}
                                    options={pairOptions}
                                    placeholder="Pairs"
                                    isMulti
                                    className="w-100"
                                />
                            </div>
                            <div className="col-md-2 col-sm-6 col-6 my-1">
                                <Select
                                    value={selectedType}
                                    onChange={(selectedOption) => setSelectedType(selectedOption || null)}
                                    options={typeOptions}
                                    placeholder="Type"
                                    className="w-100"
                                    isClearable
                                />
                            </div>
                            <div className="col-md-2 col-sm-6 col-4 my-1">
                                <Select
                                    value={selectedSort}
                                    onChange={(selectedOption) => setSelectedSort(selectedOption)}
                                    options={sortOptions}
                                    placeholder="Sort"
                                    className="w-100"
                                    isClearable
                                />
                            </div>
                            <div className="col-md-3 col-sm-6 col-8 my-1 mb-3">
                                <DateRangePickerComponent
                                    startDate={startDate}
                                    endDate={endDate}
                                    onDatesChange={handleDatesChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                {data && Object.keys(data).map(date => (
                    <div key={date} className="col-lg-6">
                        <div className="mb-4 p-3 border rounded grouped-trades">
                            <h2>{date}</h2>
                            <ul className="list-unstyled">
                                {data[date].map((trade: any) => (
                                    <li key={trade.id} className="mb-3">
                                        <div className={`card ${trade.profit > 0 ? 'bg-success' : 'bg-danger'} text-white`}>
                                            <div className="card-body">
                                                <h5 className="card-title">{trade.pair}</h5>
                                                <p className="card-text">
                                                    {trade.buy ? 'Buy' : trade.sell ? 'Sell' : 'Unknown'}<br />
                                                    Commission: {trade.commission} (Assuming commission is a field in your data)<br />
                                                    Profit: {trade.profit}<br />
                                                    Pips: {trade.pips}<br />
                                                    Lot Size: {trade.lot_size}
                                                </p>
                                                <TradeModal
                                                    buttonTitle='View'
                                                    initialTrade={trade}
                                                    view={true}
                                                    rules={rules}
                                                    onTradeSaved={() => {}}/>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>

        </Layout>
    );
}

export default GroupedTrades;
