import React from 'react';
import '../styles/components/statistics.scss';
import {Table} from "react-bootstrap";

function MarketStatistics({ data }: any) {

    const headers = [
        { title: 'Pair', key: 'pair' },
        { title: 'Avg Pips', key: 'avgPips' },
    ];

    const currentHourLabel = `${new Date().getHours()}h`;
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const currentDayLabel = `${days[new Date().getDay()]} ${currentHourLabel}`;

    return (
        <>
            <h2>Current Market Journal Info</h2>
            <Table striped bordered hover variant='dark'>
                <thead>
                <tr>
                    <th></th>
                    <th colSpan={headers.length}>{currentHourLabel}</th>
                    <th colSpan={headers.length}>{currentDayLabel}</th>
                </tr>
                <tr>
                    <th></th>
                    {headers.map((header) => (
                        <th key={`${header.title}-hour`}>{header.title}</th>
                    ))}
                    {headers.map((header) => (
                        <th key={`${header.title}-day`}>{header.title}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.currentHour.map((item: any, index: number) => (
                    <tr key={item.pair}>
                        <td>{index === 0 ? '' : item.pair}</td>
                        {headers.map(header => (
                            <td key={`${header.key}-hour`}>{item[header.key]}</td>
                        ))}
                        {data.currentDayHour[index] && headers.map(header => (
                            <td key={`${header.key}-day`}>{data.currentDayHour[index][header.key]}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );


}

export default MarketStatistics;
