import React, {useState} from 'react';
import { Trade } from '../models/trade';
import '../styles/components/card.scss';
import CustomModal from "./custom-modal";
import FullScreenImageModal from "./viewimage";
import {useTradingPlan} from "../hooks/trading-plan.hook";
import TradeSummaryTable from "../pages/TradeSummaryTable";

const TradesTable = ({ data, deleteTrade, handleCloseModal }: any) => {

    const { rules } = useTradingPlan();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState('');
    const [deleteMessage, setDeleteMessage] = useState('');
    const [tradeId, setTradeId] = useState(0);

    const [imageUrl, setImageUrl] = useState('');

    const handleImageClick = (image:string | null) => {
        if (image !== null) {
            setImageModalVisible(true);
            setImageUrl(image);
        }
    }

    const openDeleteModal = async (tradeId: number) => {
        setTradeId(tradeId);
        setDeleteTitle('Delete Trade');
        setDeleteMessage('Are you sure you want to delete this trade?');
        setShowDeleteModal(true);
    };

    const handleClose = () => {
        setShowDeleteModal(false);
    };

    const handleCloseImageModal = () => {
        setImageModalVisible(false);
    }

    const [isImageModalVisible, setImageModalVisible] = useState(false);

    return (

        <div>

            <TradeSummaryTable
                tradingPlanSummary={data?.data}
                rules={rules}
                openDeleteModal={openDeleteModal}
                handleImageClick={handleImageClick}
                handleCloseModal={handleCloseModal} />
            {/*{data?.data?.map((trade: Trade) => (*/}
            {/*    <TradeCard*/}
            {/*        key={trade.id}*/}
            {/*        rules={rules}*/}
            {/*        trade={trade}*/}
            {/*        handleImageClick={handleImageClick}*/}
            {/*        openDeleteModal={openDeleteModal}*/}
            {/*        handleCloseModal={handleCloseModal}*/}
            {/*    />*/}
            {/*))}*/}

            <FullScreenImageModal
                isVisible={isImageModalVisible}
                imageUrl={imageUrl}
                onClose={handleCloseImageModal}
            />
            <CustomModal
                show={showDeleteModal}
                handleClose={handleClose}
                title={deleteTitle}
                confirmButtonVariant='danger'
                actionTitle='Delete'
                content={
                    <p>{deleteMessage}</p>
                }
                onSubmit={() => deleteTrade(tradeId, handleClose)}
            />
        </div>

    );
};

export default TradesTable;
