import React, {useEffect, useState} from 'react';
import moment, { Moment } from 'moment';
import 'react-dates/initialize';
import {DateRangePicker, FocusedInputShape} from 'react-dates';

interface CalendarProps {
    startDate: Moment;
    endDate: Moment;
    onDatesChange: any;
}

const DateRangePickerComponent = ({ startDate, endDate, onDatesChange }: CalendarProps) => {
    const [localStartDate, setLocalStartDate] = useState<Moment>(moment(startDate));
    const [localEndDate, setLocalEndDate] = useState<Moment>(moment(endDate));
    const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
    const [selectedLabel, setSelectedLabel] = useState('Last 30 days');

    useEffect(() => {
        // Synchronize local state with props
        setLocalStartDate(moment(startDate));
        setLocalEndDate(moment(endDate));
    }, [startDate, endDate]);

    const handleLocalDateChange = ({ startDate, endDate }: any) => {
        if (startDate) setLocalStartDate(startDate);
        if (endDate) setLocalEndDate(endDate);
    };

    useEffect(() => {
        if (!localStartDate.isSame(startDate) || !localEndDate.isSame(endDate)) {
            onDatesChange({ startDate: localStartDate, endDate: localEndDate });
        }
    }, [localEndDate]);

    const predefinedRanges = [
        { label: 'Last 30 days', start: () => moment().subtract(30, 'days'), end: () => moment() },
        { label: 'Last 14 days', start: () => moment().subtract(14, 'days'), end: () => moment() },
        { label: 'Last 90 days', start: () => moment().subtract(90, 'days'), end: () => moment() },
        { label: 'This Week', start: () => moment().startOf('week'), end: () => moment().endOf('week') },
        { label: 'This Month', start: () => moment().startOf('month'), end: () => moment().endOf('month') },
        { label: 'Previous Month', start: () => moment().subtract(1, 'month').startOf('month'), end: () => moment().subtract(1, 'month').endOf('month') },
        { label: 'Current Quarter', start: () => moment().startOf('quarter'), end: () => moment().endOf('quarter') },
        { label: 'Previous Quarter', start: () => moment().subtract(1, 'quarter').startOf('quarter'), end: () => moment().subtract(1, 'quarter').endOf('quarter') },
        { label: 'This Year', start: () => moment().startOf('year'), end: () => moment().endOf('year') },
        { label: 'Previous Year', start: () => moment().subtract(1, 'year').startOf('year'), end: () => moment().subtract(1, 'year').endOf('year') },
    ];

    const renderCalendarInfo = () => {
        return (
            <div className='calendar-dropdown'>
                <label>Ranges: </label>
                <select
                    value={selectedLabel}
                    onChange={(e) => {
                        const selectedRange = predefinedRanges.find(range => range.label === e.target.value);
                        if (selectedRange) {
                            setLocalStartDate(selectedRange.start());
                            setLocalEndDate(selectedRange.end());
                            setSelectedLabel(e.target.value);
                        }
                    }}
                >
                    {predefinedRanges.map(range => (
                        <option key={range.label} value={range.label}>
                            {range.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    };
    return (
        <>
            <DateRangePicker
                startDate={localStartDate}
                startDateId="start_date_id"
                endDate={localEndDate}
                endDateId="end_date_id"
                onDatesChange={handleLocalDateChange}
                isOutsideRange={() => false}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)}
                renderCalendarInfo={renderCalendarInfo}
            />
        </>
    );
};

export default DateRangePickerComponent;
