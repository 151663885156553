import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {APP_API_BASE} from "./constants";
import {prepareHeaders} from "../utils/apiUtils";


export const tradingPlanApi = createApi({
    reducerPath: 'tradingPlan',
    baseQuery: fetchBaseQuery(
        {
            baseUrl: APP_API_BASE,
            prepareHeaders: prepareHeaders,
        }),
    tagTypes: ['Rule', 'Tip', 'Trading Plan'],
    endpoints: (builder) => ({
        getTradingPlan: builder.query({
            query: () => ({
                url: `trading-plan`,
                method: 'GET'
            }),
            providesTags: (result) =>
                result ? [{ type: 'Trading Plan', id: result.id }, { type: 'Trading Plan', id: 'LIST' }] : []

        }),
        editTradingPlan: builder.mutation({
            query: (data) => ({
                url: `trading-plan`,
                method: 'PATCH',
                body: data
            }),
            invalidatesTags: [{ type: 'Trading Plan', id: 'LIST' }]
        }),
        getRules: builder.query({
            query: () => ({
                url: `rules`,
                method: 'GET'
            }),
            providesTags: (result) =>
                result ? [...result.map(({ id }: { id: number }) => ({ type: 'Rule', id })), { type: 'Rule', id: 'LIST' }] : []

        }),
        createRule: builder.mutation({
            query: (rule) => ({
                url: `rules`,
                method: 'POST',
                body: rule
            }),
            invalidatesTags: [{ type: 'Rule', id: 'LIST' }]
        }),
        editRule: builder.mutation({
            query: ({ rule, ruleId})  => ({
                url: `rules/` + ruleId,
                method: 'PATCH',
                body: rule
            }),
            invalidatesTags: [{ type: 'Rule', id: 'LIST' }]
        }),
        deleteRule: builder.mutation({
            query: (ruleId) => ({
                url: 'rules/' + ruleId,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'Rule', id: 'LIST' }]
        }),
        getTips: builder.query({
            query: () => ({
                url: `tips`,
                method: 'GET'
            }),
            providesTags: (result) =>
                result ? [...result.map(({ id }: { id: number }) => ({ type: 'Tip', id })), { type: 'Tip', id: 'LIST' }] : []
        }),
        createTip: builder.mutation({
            query: (tip) => ({
                url: `tips`,
                method: 'POST',
                body: tip
            }),
            invalidatesTags: [{ type: 'Tip', id: 'LIST' }]
        }),
        editTip: builder.mutation({
            query: ({ tip, tipId })  => ({
                url: 'tips/' + tipId,
                method: 'PATCH',
                body: tip
            }),
            invalidatesTags: [{ type: 'Tip', id: 'LIST' }]
        }),
        deleteTip: builder.mutation({
            query: (tipId) => ({
                url: 'tips/' + tipId,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'Tip', id: 'LIST' }]
        }),
        getTradingPlanSummary: builder.query({
            query: () => ({
                url: `trade-summary`,
                method: 'GET'
            }),
        }),
    }),
})

export const {
    useGetTradingPlanQuery,
    useEditTradingPlanMutation,
    useGetRulesQuery,
    useCreateRuleMutation,
    useEditRuleMutation,
    useDeleteRuleMutation,
    useGetTipsQuery,
    useCreateTipMutation,
    useEditTipMutation,
    useDeleteTipMutation,
    useGetTradingPlanSummaryQuery
} = tradingPlanApi;

