import React from 'react';
import ApexChart from 'react-apexcharts';

const TimeSeriesChart = ({series, chartTitle, height = 350} : any) => {
    const allYValues = series.flatMap((s: any) => s.data.map((datapoint: any) => datapoint.y));

    const minValue = Math.min(...allYValues);
    const maxValue = Math.max(...allYValues);

    const padding = Math.max(Math.abs(minValue), Math.abs(maxValue)) * 0.1;

    const yAxisMin = minValue - padding;
    const yAxisMax = maxValue + padding;


    const chartOptions: any = {
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 2300,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 1000
            }
        },
        chart: {
            type: 'area',
            stacked: false,
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true,
                zoomedArea: {
                    fill: {
                        color: '#90CAF9',
                        opacity: 0.4
                    },
                    stroke: {
                        color: '#0D47A1',
                        opacity: 0.4,
                        width: 1
                    }
                }
            },
            toolbar: {
                autoSelected: 'zoom'
            },
            foreColor: 'white'
        },
        markers: {
            size: 2,
            hover: {
                size: 4
            }
        },
        dataLabels: {
            enabled: false
        },
        title: {
            text: '',
            align: 'left',
        },
        xaxis: {
            type: 'datetime',
        },
        yaxis: {
            min: yAxisMin,
            max: yAxisMax,
            title: {
                text: 'Value',
            },
            labels: {
                formatter: function (val: number) {
                    return val.toFixed(2);
                }
            }
        },
        tooltip: {
            shared: false,
            x: {
                format: 'dd MMM yyyy',
            },
            theme:'dark'
        }
    };

    chartOptions.title.text = chartTitle;

    return (
        <ApexChart options={chartOptions} series={series} type="area" height={height} />
    );
};

export default TimeSeriesChart;
