import React, {useEffect} from 'react';
import {Container, Dropdown, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import Notification from "./notification";
import {useDispatch, useSelector} from "react-redux";
import {removeToast} from "../store/slices/toast";
import {setUser} from "../store/slices/user";
import bgImage from '../assets/images/logo.png';
import MySidebar from "./SideBarComponent";
import CustomModal from "./custom-modal";
import TextInput from "./inputs/input";
import {useGetBalanceQuery} from "../services/balance-history";

interface IPublicLayout {
    children: React.ReactNode
}

const Layout = ({children} : IPublicLayout) => {
    const { data: accountBalance } = useGetBalanceQuery('');
    const [showModal, setShowModal] = React.useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const [baseValue, setBaseValue] = React.useState(0);
    const [percentage, setPercentage] = React.useState(0);
    const [result, setResult] = React.useState(0);
    const toasts = useSelector((state: any) => state.toast.toasts);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();
    const user = useSelector((state: any) => state.user);

    const logout = () => {
        localStorage.removeItem('token');
        navigate("/login")
    }


    useEffect(() => {
        if (accountBalance) {
            setBaseValue(accountBalance);
            setResult((baseValue * percentage) / 100)
        }
    }, [accountBalance]);


    useEffect(() => {
        const storedUserData = localStorage.getItem('token');
        if (!user.access_token && !storedUserData) {
            logout();
        }
    }, [user]);

    useEffect(() => {
        const storedUserData = localStorage.getItem('token');
        if (!storedUserData) {
            logout();
        }
        if (storedUserData && !user.access_token) {
            const parsedData = JSON.parse(storedUserData);
            dispatch(setUser(parsedData));
        }
    }, [dispatch]);

    const handleCloseToast = (id: any) => {
        dispatch(removeToast(id)); // Dispatch the removeToast action
    };

    return (
       <>
           {/*<MySidebar />*/}
           <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark" fixed='top'>
               <Container className='custom-container'>
                   <Navbar.Brand onClick={()=> navigate("/dashboard")}><img className='logo' src={bgImage} alt=""/> Journal4x</Navbar.Brand>
                   <Navbar.Toggle aria-controls="basic-navbar-nav" />
                   <Navbar.Collapse id="basic-navbar-nav">
                       <Nav className="me-auto">
                           <Nav.Link onClick={()=> navigate("/dashboard")}>Dashboard</Nav.Link>
                           <Nav.Link onClick={()=> navigate("/trades")}>Trades</Nav.Link>
                           <Nav.Link onClick={()=> navigate("/grouped-trades")}>Grouped Trades</Nav.Link>
                           <Nav.Link onClick={()=> navigate("/calendar")}>Calendar</Nav.Link>
                           <Nav.Link onClick={()=> navigate("/trading-plan")}>Trading plan</Nav.Link>
                           <NavDropdown title="Reports" id="reports-nav-dropdown">
                               <NavDropdown.Item onClick={() => navigate("/reports/hourly-analysis")}>Hourly Analysis</NavDropdown.Item>
                               <NavDropdown.Item onClick={() => navigate("/reports/daily-analysis")}>Daily Analysis</NavDropdown.Item>
                               <NavDropdown.Item onClick={() => navigate("/reports/monthly-analysis")}>Monthly Analysis</NavDropdown.Item>
                               <NavDropdown.Item onClick={() => navigate("/reports/hold-time-analysis")}>Hold time Analysis</NavDropdown.Item>
                               <NavDropdown.Item onClick={() => navigate("/reports/by-pair-analysis")}>Analysis by Pairs</NavDropdown.Item>
                           </NavDropdown>
                           <NavDropdown title="Market Journal" id="reports-nav-dropdown">
                               <NavDropdown.Item onClick={() => navigate("/market-journal/dashboard")}>Dashboard</NavDropdown.Item>
                               <NavDropdown.Item onClick={() => navigate("/market-journal/hourly")}>Hourly</NavDropdown.Item>
                               <NavDropdown.Item onClick={() => navigate("/market-journal/daily")}>Daily</NavDropdown.Item>
                               <NavDropdown.Item onClick={() => navigate("/market-journal/monthly")}>Monthly</NavDropdown.Item>
                           </NavDropdown>
                           <Nav.Link onClick={()=> navigate("/imported-trades")}>Import trades</Nav.Link>
                       </Nav>
                       <Nav>
                           <Nav.Link className='text-success fs-4 default-cursor'>${accountBalance}</Nav.Link>
                       </Nav>

                       <NavDropdown title={user.first_name} id="basic-nav-dropdown" className='username-color'>
                           <Dropdown.Item onClick={()=> navigate("/profile")}>Profile</Dropdown.Item>
                           <Dropdown.Item onClick={()=> navigate("/feedback")}>Feedback</Dropdown.Item>
                           <Dropdown.Item onClick={openModal}>Risk calculator</Dropdown.Item>
                           <Dropdown.Item onClick={() => logout()}>Log out</Dropdown.Item>
                       </NavDropdown>
                   </Navbar.Collapse>
               </Container>
           </Navbar>
           {toasts.map((toast: any) => (
               <Notification
                   key={toast.id}
                   show={true}
                   onClose={() => handleCloseToast(toast.id)}
                   title={toast.title}
                   message={toast.message}
               />
           ))}
           <div className='main-background'>
               <Container className='main-content mt-6' fluid='xxl'>
                   {children}
               </Container>
           </div>
           <footer style={{background: "black"}}>
               <Container className='py-3 text-center text-white'>
                   journal4x @{currentYear}
               </Container>
           </footer>

           <CustomModal
               showSubmitButton={false}
               size='lg'
               show={showModal}
               handleClose={closeModal}
               title="Risk Calculator"
               actionTitle="Submit"
               content={
                   <div className="row">
                       <div className="col-md-4">
                           <TextInput
                               type="number"
                               value={baseValue}
                               onChange={(val: any) => {
                                   setBaseValue(val);
                                   setResult((val * percentage) / 100);
                               }}
                               placeholder="Enter Account Balance"
                               label="Account Balance"
                           />
                       </div>
                       <div className="col-md-4">
                           <TextInput
                               type="number"
                               value={percentage}
                               onChange={(val: any) => {
                                   setPercentage(val);
                                   setResult((baseValue * val) / 100);
                               }}
                               placeholder="Enter percentage"
                               label="Percentage"
                           />
                       </div>
                       <div className="col-md-4">
                           <TextInput
                               type="number"
                               value={result}
                               onChange={(val: any) => setResult(val)}
                               placeholder=""
                               label="Calculated Value"
                           />
                       </div>
                   </div>
               }
               onSubmit={() => {
                   closeModal(); // Close the modal after submit
               }}
           />


       </>
    );
}

export default Layout;
