import React from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import {Rule} from "../models/trade";

const RuleTable = ({ rules, openCreateRuleModal, openEditRuleModal, openDeleteModal }: any) => (
    <>
        <Row className="align-items-center">
            <Col>
                <h2>Trading setups</h2>
            </Col>
            <Col className="text-end">
                <Button variant="primary" onClick={openCreateRuleModal}>
                    Create New
                </Button>
            </Col>
        </Row>
        <div className="table-container">
            <Table className="table" striped bordered hover variant="dark">
                <thead>
                <tr>
                    <th>Rule Name</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {rules?.map((rule: Rule) => (
                    <tr key={rule.id}>
                        <td>{rule.rule ?? '-'}</td>
                        <td className="actions">
                            <Button variant="primary" className="mb-3 mt-3" onClick={() => openEditRuleModal(rule)}>
                                Edit
                            </Button>
                            <Button variant="danger" onClick={() => openDeleteModal(rule, 'Delete rule')}>
                                Delete
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    </>
);

export default RuleTable;
