import React, {useEffect, useState} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import DateTimePicker from "react-datetime-picker";

interface JournalFormProps {
    show: boolean;
    onHide: () => void;
    onSave: (data: any) => void;
    initialData?: any;
    isEditMode?: boolean;
}
const MarketJournalForm = ({ show, onHide, onSave, initialData, isEditMode = false }: JournalFormProps) => {
    const [journalData, setJournalData] = useState({
        dateTime: new Date(),
        pair: '',
        newsDetails: '',
        pattern: '',
        notes: '',
        affectedByNews: false,
        liquidityGrab: false,
        fakeOut: false,
        moveContinuation: false,
        movedPips: 0,
        globalEvents: '',
        marketSentiment: 'Bullish'
    });


    const sentiment = ['Bullish', 'Bearish', 'Consolidation'];
    const pattern = ['Move Up', 'Move Down'];


    useEffect(() => {
        if (isEditMode && initialData) {
            setJournalData(initialData);
        }
    }, [isEditMode, initialData]);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setJournalData(prev => ({ ...prev, [name]: value }));
    };

    const handleDateChange = (date: any) => {
        setJournalData(prev => ({ ...prev, dateTime: date }));
    };

    const handleCheckboxChange = (e: any) => {
        const { name, checked } = e.target;
        setJournalData(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const handleSubmit = () => {
        onSave(journalData);
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{!isEditMode ? 'Create New' : 'Edit'} Market Journal Entry</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="container">
                    <div className="row mb-3">
                        <div className="col-lg-4 col-md-6 mb-2">
                            <Form.Group>
                                <Form.Label>Date & Time</Form.Label>
                                <DateTimePicker
                                    onChange={(date: any) => handleDateChange(date)}
                                    value={journalData.dateTime}
                                />
                            </Form.Group>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <Form.Group>
                                <Form.Label>Pair</Form.Label>
                                <Form.Control type="text" name="pair" value={journalData.pair} placeholder="XAU/USD" onChange={handleChange} />
                            </Form.Group>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <Form.Group>
                                <Form.Label>Market Sentiment</Form.Label>
                                <select
                                    name="marketSentiment"
                                    className="form-control"
                                    value={journalData.marketSentiment || ""}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled>Select a trend...</option>
                                    {sentiment?.map((trend: string) => (
                                        <option key={trend} value={trend}>{trend}</option>
                                    ))}
                                </select>
                            </Form.Group>
                        </div>

                        <div className="col-lg-4 col-md-6 mb-2">
                            <Form.Group>
                                <Form.Label>Moved Pips</Form.Label>
                                <Form.Control type="number" name="movedPips" value={journalData.movedPips} onChange={handleChange} />
                            </Form.Group>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <Form.Group>
                                <Form.Label>Global Events</Form.Label>
                                <Form.Control type="text" name="pattern" value={journalData.globalEvents} onChange={handleChange} />
                            </Form.Group>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <Form.Group>
                                <Form.Label>Move</Form.Label>
                                <select
                                    name="pattern"
                                    className="form-control"
                                    value={journalData.pattern || ""}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled>Select a move...</option>
                                    {pattern?.map((trend: string) => (
                                        <option key={trend} value={trend}>{trend}</option>
                                    ))}
                                </select>
                            </Form.Group>
                            {/*<Form.Group>*/}
                            {/*    <Form.Label>Pattern</Form.Label>*/}
                            {/*    <Form.Control type="text" name="pattern" value={journalData.pattern} onChange={handleChange} />*/}
                            {/*</Form.Group>*/}
                        </div>

                        <div className="col-lg-6 col-md-6 mb-2">
                            <Form.Group>
                                <Form.Label>News Details</Form.Label>
                                <Form.Control as="textarea" name="newsDetails" value={journalData.newsDetails} onChange={handleChange} />
                            </Form.Group>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <Form.Group>
                                <Form.Label>Notes</Form.Label>
                                <Form.Control as="textarea" name="notes" value={journalData.notes} onChange={handleChange} />
                            </Form.Group>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <Form.Group>
                                <Form.Label>Affected By News</Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    name="affectedByNews"
                                    checked={journalData.affectedByNews}
                                    onChange={handleCheckboxChange}
                                    label="Yes"
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <Form.Group>
                                <Form.Label>Fake Out</Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    name="fakeOut"
                                    checked={journalData.fakeOut}
                                    onChange={handleCheckboxChange}
                                    label="Yes"
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <Form.Group>
                                <Form.Label>Liquidity grab</Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    name="liquidityGrab"
                                    checked={journalData.liquidityGrab}
                                    onChange={handleCheckboxChange}
                                    label="Yes"
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <Form.Group>
                                <Form.Label>Move continuation</Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    name="moveContinuation"
                                    checked={journalData.moveContinuation}
                                    onChange={handleCheckboxChange}
                                    label="Yes"
                                />
                            </Form.Group>
                        </div>
                    </div>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
                <Button variant="primary" onClick={handleSubmit}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MarketJournalForm;
