import React from 'react';
import {
    useGetMarketJournalDetailsQuery,
} from "../../services/market-journal";
import Layout from "../../components/layout";
import StatsCard2 from "../../components/statscard2";
import {BsGraphUp} from "react-icons/bs";
import {Params, useLocation, useParams} from "react-router-dom";
import TimeSeriesChart from "../../components/charts/timeserieschart";
import PairsPieChart from "../../components/charts/piechart";

const MarketJournalProfile = () => {
    const { type, value } = useParams<Params>();
    const sanitizedValue = value?.replace(/h$/, '');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const startDate = queryParams.get('startDate');
    const endDate = queryParams.get('endDate');
    const pair = queryParams.get('pair');
    const { data, isLoading, isError } = useGetMarketJournalDetailsQuery({ reportType: type, value: sanitizedValue, startDate, endDate, pair})

    if (isLoading) {
        return <div>Loading...</div>; // Render loading state
    }

    if (isError || !data) {
        return <div>Error loading data</div>; // Render error state
    }

    const {
        affectedByNewsData,
        fakeOutData,
        liquidityGrabData,
        moveContinuationData,
        movedPipsData,
    } = data.chartData;


    const { patternChartData, sentimentChartData } = data;
    const charts = [
        { title: "Number of Pips per Day", data: movedPipsData, type: 'col-lg-12' },
        { title: "Number of Fake Outs", data: fakeOutData, type: 'col-lg-6' },
        { title: "Number of Affected By News Moves", data: affectedByNewsData, type: 'col-lg-6' },
        { title: "Number of Liquidity Grabs", data: liquidityGrabData, type: 'col-lg-6' },
        { title: "Number of Move Continuations", data: moveContinuationData, type: 'col-lg-6' }
    ];

    const avgPipsByNewsConfig = data?.avgPipsByNews?.map((newsData: any) => ({
        title: newsData.newsDetails,
        data: parseFloat(newsData.avgPips).toFixed(2),
        IconComponent: BsGraphUp, // Or any other relevant icon
        bg: "light",
        textColor: "black",
    }));

    const avgPipsByPatternConfig = data?.avgPipsByPattern?.map((patternData: any) => ({
        title: patternData.pattern,
        data: parseFloat(patternData.avgPips).toFixed(2),
        IconComponent: BsGraphUp,
        bg: "light",
        textColor: "black",
    }));

    const extremePipsByPatternConfig = data?.extremePipsByPattern?.map((patternData: any) => ({
        title: `${patternData.pattern} (Biggest)`,
        data: patternData.biggestPipMove,
        IconComponent: BsGraphUp, // Adjust as needed
        bg: "light",
        textColor: "black",
    }));

    const extremePipsByPatternLowestConfig = data?.extremePipsByPattern?.map((patternData: any) => ({
        title: `${patternData.pattern} (Lowest)`,
        data: patternData.lowestPipMove,
        IconComponent: BsGraphUp, // Adjust as needed
        bg: "light",
        textColor: "black",
    }));

    const extremePipsBySentimentConfig = data?.extremePipsBySentiment?.map((sentimentData: any) => ({
        title: `${sentimentData.marketSentiment} (Biggest)`,
        data: sentimentData.biggestPipMove,
        IconComponent: BsGraphUp, // Adjust as needed
        bg: "light",
        textColor: "black",
    }));

    const extremePipsBySentimentLowestConfig = data?.extremePipsBySentiment?.map((sentimentData: any) => ({
        title: `${sentimentData.marketSentiment} (Lowest)`,
        data: sentimentData.lowestPipMove,
        IconComponent: BsGraphUp, // Adjust as needed
        bg: "light",
        textColor: "black",
    }));

    return (
        <Layout>
            <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
                <h2>{value} analysis</h2>
            </div>

            <div className="row">
                {charts.map((chart, index) => (
                    <div key={index} className={`chart-container ${chart.type}`}>
                        <TimeSeriesChart
                            series={[{ data: Object.entries(chart.data).map(([date, value]) => ({ x: date, y: value })) }]}
                            chartTitle={chart.title}
                        />
                    </div>
                ))}
                <div className="col-md-6 mb-5">
                    <h3>Pattern Occurrences</h3>
                    <PairsPieChart chartPairData={patternChartData} />
                </div>

                <div className="col-md-6 mb-5">
                    <h3>Market Sentiment Occurrences</h3>
                    <PairsPieChart chartPairData={sentimentChartData} />
                </div>
            </div>

            <div className="stats-container mb-5">

                <h3>Average Pips by News</h3>
                <StatsCard2 config={avgPipsByNewsConfig} />

                <h3>Average Pips by Pattern</h3>
                <StatsCard2 config={avgPipsByPatternConfig} />

                <h3>Biggest Pip Move by Pattern</h3>
                <StatsCard2 config={extremePipsByPatternConfig} />

                <h3>Lowest Pip Move by Pattern</h3>
                <StatsCard2 config={extremePipsByPatternLowestConfig} />

                <h3>Biggest Pip Move by Sentiment</h3>
                <StatsCard2 config={extremePipsBySentimentConfig} />

                <h3>Lowest Pip Move by Sentiment</h3>
                <StatsCard2 config={extremePipsBySentimentLowestConfig} />
            </div>


            <h2>Insights</h2>
            <div className="insight-container mt-4">
                {data?.insights.map((insight: string, index: number) => (
                    <div className="insight-card" key={index}>
                        <div className="insight-header">
                            <h5>Insight {index + 1}</h5>
                        </div>
                        <div className="insight-body">
                            {insight}
                        </div>
                    </div>
                ))}
            </div>
        </Layout>
    );
}

export default MarketJournalProfile;
