import React from 'react';
import { Button } from 'react-bootstrap';
import TradeModal from "./modal";
import {Rule, Trade} from "../models/trade";

type TradeCardProps = {
    trade: Trade;
    rules: Rule[];
    handleImageClick: (image: string) => void;
    openDeleteModal: (tradeId: number) => void;
    handleCloseModal: () => void;
    showActions?: boolean
};

const TradeCard = ({ trade, handleImageClick, openDeleteModal, handleCloseModal, rules, showActions = true }: TradeCardProps) => {
    return (
        <div className="nft">
            <div className='main'>
                <img
                    className='tokenImage'
                    src={trade.image ? trade.image : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"}
                    alt="Trade image"
                    onClick={() => handleImageClick(trade.image)}
                />
                <h2>{trade.pair !== '' ? trade.pair : '-'}</h2>
                <p className='description'>
                    {trade.buy ? 'Buy' : 'Sell'}
                </p>
                <p className='lot_size'>
                    Volume: {trade.lot_size}
                </p>
                <div className='tokenInfo'>
                    <div className={`price ${trade.profit < 0 ? 'negative' : ''}`}>
                        <p>
                            ${trade.profit ?? '-'}
                        </p>
                    </div>
                    <div className="duration">
                        <p>{new Date(trade.date_opened).toLocaleDateString()}</p>
                    </div>
                </div>
                <hr />
                {!showActions &&
                    <div className='creator'>
                        <div className="actions">
                            <TradeModal
                                buttonTitle='View'
                                initialTrade={trade}
                                view={true}
                                rules={rules}
                                onTradeSaved={() => {}}/>
                        </div>
                    </div>
                }
                {showActions &&
                    <div className='creator'>
                        <div className="actions">
                            <TradeModal
                                buttonTitle='Edit'
                                initialTrade={trade}
                                onTradeSaved={handleCloseModal}
                                rules={rules} />
                            <Button variant="danger" onClick={() => openDeleteModal(trade.id)}>Delete</Button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default TradeCard;
