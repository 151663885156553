import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APP_API_BASE } from './constants';
import { prepareHeaders } from '../utils/apiUtils';

export const marketJournalApi = createApi({
    reducerPath: 'marketJournal',
    baseQuery: fetchBaseQuery({
        baseUrl: APP_API_BASE,
        prepareHeaders: prepareHeaders,
    }),
    endpoints: (builder) => ({
        getNotes: builder.query({
            query: ({startDate, endDate}) => ({
                url: `market-journal/market-notes?startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
            }),
        }),
        createNote: builder.mutation({
            query: (body) => ({
                url: `market-journal`,
                method: 'POST',
                body: body,
            }),
        }),
        updateNote: builder.mutation({
            query: (note) => ({
                url: 'market-journal/' + note.id,
                method: 'PUT',
                body: note,
            }),
        }),
        deleteNote: builder.mutation({
            query: (noteId) => ({
                url: 'market-journal/' + noteId,
                method: 'DELETE',
            }),
        }),
        getHourlyMarketJournal: builder.query({
            query: ({pair, startDate, endDate}) => ({
                url: `market-journal/hourly?pair=${pair}&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
            }),
        }),
        getDailyMarketJournal: builder.query({
            query: ({pair, startDate, endDate}) => ({
                url: `market-journal/daily?pair=${pair}&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
            }),
        }),
        getMonthlyMarketJournal: builder.query({
            query: ({pair, startDate, endDate}) => ({
                url: `market-journal/monthly?pair=${pair}&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
            }),
        }),
        getMarketJournalDetails: builder.query({
            query: ({reportType, value, startDate, endDate, pair}) => ({
                url: `market-journal/details/${reportType}/${value}?startDate=${startDate}&endDate=${endDate}&pair=${pair}`,
                method: 'GET',
            }),
        }),
        getAvailableMarketPairs: builder.query({
            query: () => ({
                url: `market-journal/available-pairs`,
                method: 'GET',
            }),
        }),
        getCurrentHourData: builder.query({
            query: () => ({
                url: `market-journal/current-data`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetNotesQuery,
    useGetHourlyMarketJournalQuery,
    useGetDailyMarketJournalQuery,
    useGetMonthlyMarketJournalQuery,
    useCreateNoteMutation,
    useUpdateNoteMutation,
    useDeleteNoteMutation,
    useGetMarketJournalDetailsQuery,
    useGetAvailableMarketPairsQuery,
    useGetCurrentHourDataQuery
} = marketJournalApi;
