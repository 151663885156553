import {Row} from "react-bootstrap";
import StatCard from "./statcard";

const StatsCard2 = ({ config }: any) => {
    return (
        <Row className="gx-4">
            {config?.map((stat: any, index: number) => (
                <StatCard key={index} {...stat} />
            ))}
        </Row>
    );
};

export default StatsCard2;
