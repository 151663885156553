import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APP_API_BASE } from './constants';
import { prepareHeaders } from '../utils/apiUtils';

export const tradesApi = createApi({
    reducerPath: 'trades',
    baseQuery: fetchBaseQuery({
        baseUrl: APP_API_BASE,
        prepareHeaders: prepareHeaders,
    }),
    tagTypes: ['Trade', 'Trading Plan'],
    endpoints: (builder) => ({
        getTrades: builder.query({
            query: ({ page = 1, limit = 20, startDate, endDate, pairs, sort, type }) => {
                let queryParams = `pageNumber=${page}&resultsPerPage=${limit}&startDate=${startDate}&endDate=${endDate}`;
                if (pairs && pairs.length > 0) {
                    const pairStrings = pairs.map((pair: any) => pair.value).join(",");
                    queryParams += `&pairs=${pairStrings}`;
                }
                if (type) {
                    queryParams += `&type=${type.value}`;
                }
                if (sort) {
                    queryParams += `&sort=${sort.value}`;
                }

                return {
                    url: `trades?${queryParams}`,
                    method: 'GET',
                };
            },
            providesTags: (result) =>
                result ? [{ type: 'Trade', id: 'LIST' }] : [],
        }),
        getGroupedTrades: builder.query({
            query: ({ startDate, endDate, pairs, sort, type }) => {
                let queryParams = `startDate=${startDate}&endDate=${endDate}`;
                if (pairs && pairs.length > 0) {
                    const pairStrings = pairs.map((pair: any) => pair.value).join(",");
                    queryParams += `&pairs=${pairStrings}`;
                }
                if (type) {
                    queryParams += `&type=${type.value}`;
                }
                if (sort) {
                    queryParams += `&sort=${sort.value}`;
                }

                return {
                    url: `trades/groupedByDate?${queryParams}`,
                    method: 'GET',
                };
            }
        }),
        getCalendarTrades: builder.query({
            query: ({ page = 1, limit = 8, startDate, endDate, pairs, type }) => {
                let queryParams = `startDate=${startDate}&endDate=${endDate}`;
                if (pairs && pairs.length > 0) {
                    const pairStrings = pairs.map((pair: any) => pair.value).join(",");
                    queryParams += `&pairs=${pairStrings}`;
                }
                if (type) {
                    queryParams += `&type=${type.value}`;
                }
                return {
                    url: `trades/calendar?${queryParams}`,
                    method: 'GET',
                };
            },
        }),
        getDashboard: builder.query({
            query: ({startDate, endDate}) => ({
                url: `trades/summary?startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
            }),
        }),
        getFilters: builder.query({
            query: ({startDate, endDate}) => ({
                url: `trades/filters?startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
            }),
        }),
        createTrade: builder.mutation({
            query: (trade) => ({
                url: 'trades',
                method: 'POST',
                body: trade,
            }),
            invalidatesTags: [{ type: 'Trade', id: 'LIST' }, { type: 'Trading Plan', id: 'LIST' }],
        }),
        updateTrade: builder.mutation({
            query: ({ trade, tradeId }) => ({
                url: 'trades/' + tradeId,
                method: 'PUT',
                body: trade,
            }),
            invalidatesTags: [{ type: 'Trade', id: 'LIST' }],
        }),
        deleteTrade: builder.mutation({
            query: (tradeId) => ({
                url: 'trades/' + tradeId,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Trade', id: 'LIST' }, { type: 'Trading Plan', id: 'LIST' }],
        }),
        uploadFile: builder.mutation({
            query: (body) => ({
                url: 'trades/upload',
                method: 'POST',
                con: 'multipart/form-data',
                body: body,
            })
        }),
        importTrades: builder.mutation({
            query: (body) => ({
                url: 'trades/import',
                method: 'POST',
                body: body,
            })
        }),
        sendFeedback: builder.mutation({
            query: (body) => ({
                url: 'trades/send-email',
                method: 'POST',
                body: body,
            })
        }),
    }),
});

export const {
    useGetTradesQuery,
    useGetGroupedTradesQuery,
    useGetCalendarTradesQuery,
    useCreateTradeMutation,
    useUpdateTradeMutation,
    useDeleteTradeMutation,
    useGetDashboardQuery,
    useUploadFileMutation,
    useImportTradesMutation,
    useSendFeedbackMutation,
    useGetFiltersQuery
} = tradesApi;
