import React, {useEffect, useState} from 'react';
import Layout from "../components/layout";
import {useDeleteTradeMutation, useGetFiltersQuery, useGetTradesQuery} from "../services/trades";
import '../styles/components/trades.scss';
import TradeModal from "../components/modal";
import TradesTable from "../components/table";
import {useTradingPlan} from "../hooks/trading-plan.hook";
import {useDispatch} from "react-redux";
import {addToast} from "../store/slices/toast";
import {useGetTradingPlanQuery} from "../services/trading_plan";
import DateRangePickerComponent from "../components/DateRangePickerComponent";
import moment from "moment/moment";
import PaginationComponent from "../components/pagination";
import Select from "react-select";

const Trades = () => {
    const [startDate, setStartDate] = useState(moment().subtract(90, 'days'));
    const [sendStartDate, setSendStartDate] = useState(moment().subtract(90, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment());
    const [sendEndDate, setSendEndDate] = useState(moment().format('YYYY-MM-DD'));
    const { rules } = useTradingPlan();
    const [queryArgs, setQueryArgs] = useState({ page: 1, limit: 20 });
    const [selectedType, setSelectedType] = useState<any>(null);
    const [selectedSort, setSelectedSort] = useState<any>(null);
    const [selectedPairs, setSelectedPairs] = useState<any>([]);
    const { data, refetch } = useGetTradesQuery({...queryArgs, startDate: sendStartDate, endDate: sendEndDate, pairs: selectedPairs, sort: selectedSort, type: selectedType});
    const { data: filters, refetch: refetchFilters } = useGetFiltersQuery({startDate: sendStartDate, endDate: sendEndDate});
    const [sendDeleteTrade] = useDeleteTradeMutation();
    const { refetch: updateBalance } = useGetTradingPlanQuery('');


    const typeOptions = [
        { value: 'Buy', label: 'Buy' },
        { value: 'Sell', label: 'Sell' }
    ];

    const sortOptions = filters?.sortOptions || [];

    const pairOptions = filters?.pairs?.map((pair: string) => ({ value: pair, label: pair })) || [];


    const dispatch = useDispatch();

    const showToast = (message: string, title: string) => {
        const toast = { id: Date.now(), title, message };
        dispatch(addToast(toast));
    };

    const itemsPerPage = 20;

    const handlePageChange = (newPage: number) => {
        setQueryArgs({ page: newPage, limit: itemsPerPage });
    };

    const handleDatesChange = ({ startDate, endDate }: any) => {
        setStartDate(startDate);
        setSendStartDate(startDate.format('YYYY-MM-DD'));
        setEndDate(endDate);
        setSendEndDate(endDate.format('YYYY-MM-DD'));
        refetch();
        refetchFilters();
    };


    useEffect(() => {
        refetch();
    }, [queryArgs]);


    const deleteTrade = async (tradeId: number, closeModal: () => void) => {
        await sendDeleteTrade(tradeId);
        refetch();
        updateBalance();
        showToast('Trade Deleted Successfully!', 'Delete Trade');
        closeModal();
    }

    const handleCloseModal = () => {
        refetch();
    }

        return (
        <Layout>
            <div className="container mt-3">
                <div className="row justify-content-between align-items-center">
                    <div className="col-lg-2 col-md-12 col-12">
                        <h2>Trades</h2>
                    </div>
                    <div className="col-lg-10 col-md-12">
                        <div className="row">
                            <div className="col-md-2 offset-md-3 col-sm-6 col-6 my-1">
                                <Select
                                    value={selectedPairs}
                                    onChange={(selectedOptions) => setSelectedPairs(selectedOptions)}
                                    options={pairOptions}
                                    placeholder="Pairs"
                                    isMulti
                                    className="w-100"
                                />
                            </div>
                            <div className="col-md-2 col-sm-6 col-6 my-1">
                                <Select
                                    value={selectedType}
                                    onChange={(selectedOption) => setSelectedType(selectedOption || null)}
                                    options={typeOptions}
                                    placeholder="Type"
                                    className="w-100"
                                    isClearable
                                />
                            </div>
                            <div className="col-md-2 col-sm-6 col-4 my-1">
                                <Select
                                    value={selectedSort}
                                    onChange={(selectedOption) => setSelectedSort(selectedOption)}
                                    options={sortOptions}
                                    placeholder="Sort"
                                    className="w-100"
                                    isClearable
                                />
                            </div>
                            <div className="col-md-3 col-sm-6 col-8 my-1 mb-3">
                                <DateRangePickerComponent
                                    startDate={startDate}
                                    endDate={endDate}
                                    onDatesChange={handleDatesChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mb-3'>
                <TradeModal
                    rules={rules}
                    buttonTitle='Create new trade'
                    buttonClass={'journal-button'}
                    onTradeSaved={handleCloseModal}/>
            </div>
            <TradesTable
                data={data}
                deleteTrade={deleteTrade}
                handleCloseModal={handleCloseModal}
            />
            <PaginationComponent
                currentPage={queryArgs.page|| 0}
                totalItems={data?.count || 0}
                itemsPerPage={data?.results|| 3}
                onPageChange={handlePageChange}
            />
        </Layout>
    );
}

export default Trades;
