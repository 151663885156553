import React, {useState} from 'react';
import {useGetReportQuery} from "../../services/reports";
import Layout from "../../components/layout";
import DataLoadHandler from "../../components/helpers/dataloadhandler";
import { Table} from "react-bootstrap";
import DistributedColumnChart from "../../components/charts/DistributedColumnChart";
import '../../styles/components/trades.scss';
import DateRangePickerComponent from "../../components/DateRangePickerComponent";
import moment from "moment/moment";


const ByPairAnalysis = () => {
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days'));
    const [sendStartDate, setSendStartDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment());
    const [sendEndDate, setSendEndDate] = useState(moment().format('YYYY-MM-DD'));
    const { data, isLoading, isError, refetch } = useGetReportQuery({reportType: 'byPair', startDate: sendStartDate, endDate: sendEndDate});

    const categories = data?.chart?.categories;
    const chartData = [{
        name: "Profit",
        data: data?.chart?.data
    }];

    const handleDatesChange = ({ startDate, endDate }: any) => {
        setStartDate(startDate);
        setSendStartDate(startDate.format('YYYY-MM-DD'));
        setEndDate(endDate);
        setSendEndDate(endDate.format('YYYY-MM-DD'));
        refetch();
    };


    return (
        <Layout>
            <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
                <h2>Analysis by Pairs</h2>
                <div className="flex-shrink-0">
                    <DateRangePickerComponent
                        startDate={startDate}
                        endDate={endDate}
                        onDatesChange={handleDatesChange}
                    />
                </div>
            </div>
            {categories && chartData && (
                <DistributedColumnChart
                    categories={categories}
                    series={chartData}
                    chartTitle="Hourly Analysis Overview"
                />
            )}
            <div className="table-container">
                <Table className="table mt-3 table-container" striped bordered hover variant='dark'>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Number of Trades</th>
                        <th>Profitable Trades</th>
                        <th>Losing Trades</th>
                        <th>Total Profit</th>
                        <th>Total Commission</th>
                        <th>Avg Win on Long</th>
                        <th>Avg Loss on Long</th>
                        <th>Avg Win on Short</th>
                        <th>Avg Loss on Short</th>
                        <th>Biggest Win</th>
                        <th>Biggest Loss</th>
                        <th>Win Rate</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data?.analyticsData.map((data: any) => (
                        <tr key={data.name}>
                            <td>{data.name}</td>
                            <td>{data.numberOfTrades}</td>
                            <td>{data.profitableTrades}</td>
                            <td>{data.losingTrades}</td>
                            <td>{data.totalProfit}</td>
                            <td>{data.totalCommission}</td>
                            <td>{data.avgWinOnLong}</td>
                            <td>{data.avgLossOnLong}</td>
                            <td>{data.avgWinOnShort}</td>
                            <td>{data.avgLossOnShort}</td>
                            <td>{data.biggestWin}</td>
                            <td>{data.biggestLoss}</td>
                            <td>{data.winRate}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
            <DataLoadHandler isLoading={isLoading} isError={isError}>
            </DataLoadHandler>
        </Layout>
    );
}

export default ByPairAnalysis;
