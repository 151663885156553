import React from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import {Tip} from "../models/trade";

const TipTable = ({ tips, openCreateTipModal, openEditTipModal, openDeleteModal }: any) => (
    <>
        <Row className="align-items-center">
            <Col>
                <h2>Tips</h2>
            </Col>
            <Col className="text-end">
                <Button variant="primary" onClick={openCreateTipModal}>
                    Create New
                </Button>
            </Col>
        </Row>
        <div className="table-container">
            <Table className="table" striped bordered hover variant="dark">
                <thead>
                <tr>
                    <th>Tip Name</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {tips?.map((tip: Tip) => (
                    <tr key={tip.id}>
                        <td>{tip.tip ?? '-'}</td>
                        <td className="actions">
                            <Button variant="primary" className="mb-3 mt-3" onClick={() => openEditTipModal(tip)}>
                                Edit
                            </Button>
                            <Button variant="danger" onClick={() => openDeleteModal(tip, 'Delete tip')}>
                                Delete
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    </>
);

export default TipTable;
