import React, {useState} from 'react';
import {Button, Col, Container, Row, Table} from 'react-bootstrap';
import Layout from "../../components/layout";
import MarketJournalForm from "./market-journal-form";
import {
    useCreateNoteMutation,
    useDeleteNoteMutation,
    useGetNotesQuery,
    useUpdateNoteMutation
} from "../../services/market-journal";
import CustomModal from "../../components/custom-modal";
import {addToast} from "../../store/slices/toast";
import {useDispatch} from "react-redux";
import moment from "moment";
import DateRangePickerComponent from "../../components/DateRangePickerComponent";

const MarketJournalDashboard = () => {
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days'));
    const [sendStartDate, setSendStartDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment());
    const [sendEndDate, setSendEndDate] = useState(moment().format('YYYY-MM-DD'));

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedEntryId, setSelectedEntryId] = useState<number | null>(null);
    const [selectedEntry, setSelectedEntry] = useState<any | null>(null);
    const { data, refetch } = useGetNotesQuery({startDate: sendStartDate, endDate: sendEndDate});
    const [createNote] = useCreateNoteMutation();
    const [updateNote] = useUpdateNoteMutation();
    const [deleteNote] = useDeleteNoteMutation();
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    const handleDatesChange = ({ startDate, endDate }: any) => {
        setStartDate(startDate);
        setSendStartDate(startDate.format('YYYY-MM-DD'));
        setEndDate(endDate);
        setSendEndDate(endDate.format('YYYY-MM-DD'));
        refetch();
    };

    const openDeleteModal = (entryId: number) => {
        setSelectedEntryId(entryId);
        setShowDeleteModal(true);
    };

    const showToast = (title: string, message: string) => {
        const toast = { id: Date.now(), title, message };
        dispatch(addToast(toast));
    };

    const deleteJournalEntry = async () => {
        await deleteNote(selectedEntryId);
        refetch();
        showToast('Delete Tip', 'Tip deleted successfully!')
        setShowDeleteModal(false);

    }

    const closeDeleteModal = () => {
        setSelectedEntryId(null);
        setShowDeleteModal(false);
    };

    const openEditModal = (entry: any) => {
        setSelectedEntry(entry);
        setShowModal(true);
    };

    const closeEditModal = () => {
        setSelectedEntry(null);
        setSelectedEntry(null);
    };

    const handleUpdate =  async (data: any) => {
        await updateNote(data);
        refetch();
        setSelectedEntry(null);
        closeEditModal();
    };

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedEntry(null);
    };

    const handleSaveJournal = async (data: any) => {
        await createNote(data);
        refetch();
        handleModalClose();
    };

    return (
        <Layout>
            <div className="dashboard-container">
                <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
                    <h2>Market Journal Dashboard</h2>
                    <div className="flex-shrink-0">
                        <DateRangePickerComponent
                            startDate={startDate}
                            endDate={endDate}
                            onDatesChange={handleDatesChange}
                        />
                    </div>
                </div>
                <Container>
                    <Row className="mt-4">
                        <Col>
                            <Button variant="primary" onClick={() => {setShowModal(true); setSelectedEntry(null);}}>New Market Note</Button>
                        </Col>
                    </Row>

                    <MarketJournalForm
                        show={showModal}
                        onHide={handleModalClose}
                        onSave={selectedEntry ? handleUpdate : handleSaveJournal}
                        initialData={selectedEntry}
                        isEditMode={!!selectedEntry}
                    />
                </Container>


                <div className="recent-journal-entries table-container">
                    <h3>Recent Journal Entries</h3>
                    <Table className="table mt-3" striped bordered hover variant='dark'>
                        <thead>
                        <tr>
                            <th>Date & Time</th>
                            <th>Pair</th>
                            <th>Market Sentiment</th>
                            <th>Moved Pips</th>
                            <th>Global Events</th>
                            <th>Pattern</th>
                            <th>News Details</th>
                            <th>Notes</th>
                            <th>Affected By News</th>
                            <th>Fake Out</th>
                            <th>Liquidity Grab</th>
                            <th>Move Continuation</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.map((journal: any, index: number) => (
                            <tr key={index}>
                                <td>{moment(journal.dateTime).format('DD-MM-YYYY-HH:mm')}</td>
                                <td>{journal.pair}</td>
                                <td>{journal.marketSentiment}</td>
                                <td>{journal.movedPips}</td>
                                <td>{journal.globalEvents}</td>
                                <td>{journal.pattern}</td>
                                <td>{journal.newsDetails}</td>
                                <td>{journal.notes}</td>
                                <td>{journal.affectedByNews ? 'Yes' : 'No'}</td>
                                <td>{journal.fakeOut ? 'Yes' : 'No'}</td>
                                <td>{journal.liquidityGrab ? 'Yes' : 'No'}</td>
                                <td>{journal.moveContinuation ? 'Yes' : 'No'}</td>
                                <td className="actions">
                                    <Button variant="primary" className="mb-3 mt-3" onClick={() => openEditModal(journal)}>
                                        Edit
                                    </Button>
                                    <Button variant="danger" onClick={() => openDeleteModal(journal.id)}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>

                <CustomModal
                    show={showDeleteModal}
                    handleClose={closeDeleteModal}
                    title="Delete Entry"
                    confirmButtonVariant="danger"
                    actionTitle="Delete"
                    content={<p>Are you sure you want to delete this entry?</p>}
                    onSubmit={() => deleteJournalEntry()}
                />
            </div>
        </Layout>
    );
}

export default MarketJournalDashboard;
