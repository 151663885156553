import React from 'react';
import '../styles/components/statistics.scss';
import {Table} from "react-bootstrap";

function Statistics({ summaryData }: any) {
    const renderCell = (value: number | string, isSpecial: boolean, key: any, rowKey: string) => (
        isSpecial && typeof value === 'number'
            ? <td key={key} className={value < 0 ? 'negative' : 'positive'}>
                {value < 0 ? '↓' : '↑'} {Math.abs(value)} {rowKey === 'totalProfit' ? '$' : ''}
            </td>
            : <td key={key} >{value}</td>
    );

    const headers = [
        { title: 'Today', key: 'summaryToday' },
        { title: 'This Week', key: 'summaryWeek' },
        { title: 'This Month', key: 'summaryMonth' },
    ];
    const rows = [
        { title: '% Gain', key: 'percentChange' },
        { title: 'Total Trades', key: 'totalTrades' },
        { title: 'Total Profit', key: 'totalProfit' },
        { title: 'Win Ratio', key: 'winRatio' },
        { title: 'Total Pips', key: 'totalPips' },
        { title: 'Most Traded Pair', key: 'mostTradedPair' },
    ];

    return (
        <div className="table-container">
            <Table striped bordered hover variant='dark'>
                <thead>
                <tr>
                    <th></th>
                    {headers.map((header: any) => (
                        <th key={header.title}>{header.title}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {rows.map((row: any) => (
                    <tr key={row.title}>
                        <td key={row.title}>{row.title}</td>
                        {headers.map(header => (
                            renderCell(
                                summaryData[header.key]?.[row.key],
                                row.key === 'totalPips' || row.key === 'totalProfit' || row.key === 'percentChange',
                                header.key,
                                row.key
                            )
                        ))}
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );
}

export default Statistics;
